const model = {
  fields: {
    xc_project: {
      type: 'belongsTo',
      label: '計畫',
      textKey: "name",
      modelName: "xc_project",
      getText(modeldata) {
        if (modeldata.no) {
          return modeldata.no + '-' + modeldata.name
        } else {
          return modeldata.name
        }
      },
      getExtendParamsFromValue(value, controller) {
        return {
          member: controller.$store.state.auth.currentUser.xc_employee?.id
        }
      },
    }
  },
  crudSetting: {
    index: {
      showFields: [
        'xc_project'
      ]
    },
    index_my: {
      showFields: [
        'xc_project'
      ]
    },
    create_my: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'xc_project'
              ],
            },
          ],
        },
      ],
    },
  }
}
export default model