<template>
  <WsDialog
    ref="WsDialog"
    :title="_title"
    v-model="dialog"
    @close="$_release()"
  >
    <template
      v-if="item"
      v-slot:content
    >
      <slot
        v-if="$_checkSlotHasContent('content')"
        name="content"
        :item="item"
      ></slot>
      <WsLoading v-else-if="loading.fetch"></WsLoading>
      <WsEasyTable
        v-else
        :fields="fields"
        :modelData="item"
      />
      <slot
        name="afterContent"
        :item="item"
      />
      <WsTabs
        v-if="_tabs.length && _tabs.length>1"
        v-model="tabValue"
        :items="_tabs"
        class="my-10"
      />
      <WsCrud
        class="mt-40"
        v-if="_tabs.length"
        :key="tabValue"
        v-bind="_childCrudValue"
        :modelName="_childCrudModel.modelName"
        :label="_childCrudModel.label"
        :fields="_childCrudModel.fields"
        :params="_childCrudParams"
        :createData="_childCrudParams"
        :updateData="_childCrudParams"
        :getUrlFunction="_childCrudModel.crudSetting?.index?.getUrlFunction || null"
      />
    </template>
    <template v-slot:leftActions>
      <slot
        name="leftActions"
        :item="item"
      ></slot>
      <WsBtn
        v-if="deletable"
        @click="$_onDelete()"
        :outlined="false"
        alert
      >{{$t('delete')}}</WsBtn>
    </template>
    <template v-slot:rightActions>
      <slot
        name="rightActions"
        :item="item"
      ></slot>
      <template v-if="copyable">
        <WsBtn
          v-if="pageMode"
          :to="_copyUrl"
        >複製</WsBtn>
        <WsBtn
          v-if="!pageMode"
          @click="$_onCopy()"
        >複製</WsBtn>
      </template>
      <template>
        <WsBtn
          v-if="pageMode&&!dialogUpdate&&updatable"
          :to="$_getUpdateUrl(item)"
          @click="$_onUpdate()"
          outlined
        >{{$t('edit')}}</WsBtn>
        <WsBtn
          v-else-if="updatable"
          @click="$_onUpdate()"
          outlined
        >{{$t('edit')}}</WsBtn>
        <WsBtn
          @click="close()"
          outlined
        >{{$t('cancel')}}</WsBtn>
      </template>
    </template>
  </WsDialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    item: null,
    itemIndex: null,
    loading: {
      fetch: false,
    },
    tabValue: null,
  }),
  mounted() {
    if (this._tabs?.length) {
      this.tabValue = this._tabs[0].value;
    } else {
      this.tabValue = null;
    }
  },
  methods: {
    $_checkSlotHasContent(slotName) {
      if (this.$slots[slotName] || this.$scopedSlots[slotName]) {
        return true;
      } else {
        return false;
      }
    },
    $_onCopy() {
      this.$emit("copy", {
        item: this.item,
        itemIndex: this.itemIndex,
      });
    },
    $_onUpdate() {
      this.$emit("update", {
        item: this.item,
        itemIndex: this.itemIndex,
      });
    },
    $_onDelete() {
      this.$emit("delete", {
        item: this.item,
        itemIndex: this.itemIndex,
      });
    },
    $_getFetchUrl(item) {
      if (this.getReadFetchUrl) {
        return this.getReadFetchUrl(item);
      } else {
        return `${this.modelName}/${item.id}`;
      }
    },
    async open(item, itemIndex) {
      this.dialog = true;
      this.item = item;
      if (this.fetchOnOpen) {
        try {
          this.loading.fetch = true;
          const _fetchUrl = this.$_getFetchUrl(item);
          const res = await this.$axios.get(_fetchUrl);
          this.item = res.data.data;
        } catch (error) {
          alert("讀取資料錯誤");
        } finally {
          this.loading.fetch = false;
        }
      } else {
        this.item = item;
      }
      this.itemIndex = itemIndex;
    },
    close() {
      this.dialog = false;
      this.$_release();
    },
    $_release() {
      this.item = null;
      this.itemIndex = null;
      this.$emit("close");
    },
    $_getUpdateUrl(item) {
      if (!item) {
        return "";
      }
      if (this.getUpdateUrl) {
        return this.getUpdateUrl(item);
      } else {
        return `/${this._urlModelName}/${item.id}/update`;
      }
    },
  },
  computed: {
    _id() {
      if (!this.item) {
        return null;
      } else {
        return this.item.id;
      }
    },
    _childCrudValue() {
      let childCruds = this.childCruds;
      if (!childCruds) return null;
      return this.childCruds.find((item) => item.modelName == this.tabValue);
    },
    _childCrudModel() {
      return this.$store.state.stone_model[this.tabValue];
    },
    _childCrudParams() {
      return {
        [this.modelName]: this._id,
      };
    },
    _tabs() {
      let _tabs = [];
      let childCruds = this.childCruds;
      if (childCruds) {
        childCruds?.forEach((model) => {
          _tabs.push({
            text: this.$store.state.stone_model[model.modelName].label,
            value: model.modelName,
          });
        });
      }
      if (this.cmsLog) {
        _tabs.push({
          text: "編輯歷程",
          value: "cms_log",
        });
      }
      return _tabs;
    },
    _copyUrl() {
      return `/${this._urlModelName}/create?copyfrom=${this.item.id}`;
    },
    _title() {
      if (!this.item) {
        return null;
      } else {
        return this.item[this.titleKey];
      }
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
  },
  props: {
    getReadFetchUrl: {},
    fetchOnOpen: {
      type: Boolean,
    },
    getUpdateUrl: {
      type: Function,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    dialogUpdate: {
      type: Boolean,
      default: false,
    },
    pageMode: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    updatable: {
      type: Boolean,
      default: true,
    },
    copyable: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: "name",
    },
    fields: Object,
    urlModelName: String,
    childCruds: Array,
    cmsLog: Boolean,
  },
};
</script>

<style>
</style>