import { render, staticRenderFns } from "./WsIcon.vue?vue&type=template&id=065819aa"
import script from "./WsIcon.vue?vue&type=script&lang=js"
export * from "./WsIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports