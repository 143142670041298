<template>
  <WsInfiniteScroll
    v-slot="{ items }"
    modelName="video"
    class="file_system-file-picker-video"
    :params="_params"
  >
    <WsFlexGallery>
      <WsFlexGalleryItem
        v-for="(item, index) in items"
        :key="index"
        @click.native="$_onVideoPickerClick(item, index)"
        :class="{ selected: $_isSelected(item) }"
        class="file_system-file-picker-video__item"
      >
        <WsVideo
          class="file_system-file-picker-video__item__video"
          :src="item.public_url"
        />
      </WsFlexGalleryItem>
    </WsFlexGallery>
  </WsInfiniteScroll>
</template>

<script>
export default {
  data() {
    return {
      focusIndex: null,
      focusItem: null,
    }
  },
  props: {
    storageTarget: {},
  },
  computed: {
    _params() {
      const _params = {
        order_by: 'updated_at',
        order_way: 'desc',
      }
      if (this.storageTarget) {
        _params.tags = [this.storageTarget]
      } else {
        _params.tags = ['general']
      }
      return _params
    },
  },
  methods: {
    $_isSelected(item) {
        if (!this.focusItem) {
          return false
        }
        if (this.focusItem.id == item.id) {
          return true
        } else {
          return false
        }
    },
    $_onVideoPickerClick(item, itemIndex) {
      this.focusItem = item
      this.focusIndex = itemIndex
    },
    submit() {
      let _value
      _value = this.focusItem.public_url
      this.$emit('submit', _value)
    },
  },
}
</script>

<style lang="scss">
.file_system-file-picker-video {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;

  .file_system-file-picker-video__item {
    position: relative;
    cursor: pointer;

    &.selected {
      .file_system-file-picker-video__item__video {
        border: solid 13px var(--primary);
      }
    }

    .file_system-file-picker-video__item__video {
      transition: all 0.2s;
      position: absolute;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      top: 4px;
      left: 4px;
      border: solid 1px var(--gray);
      background-size: contain;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
</style>