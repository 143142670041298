<template>
  <WsMain>
    <WsStateForm
      :fields="oneForAllFields"
      v-model="onForAllState"
    ></WsStateForm>
    <WsCard
      class="mt-20"
      v-for="(stateModel,stateModelIndex) in states"
      :key="stateModelIndex"
    >
      <WsFlex>
        <WsStateForm
          :value="stateModel"
          :fields="_modelFields"
          @input="$_onInputStateModel($event, stateModelIndex)"
        ></WsStateForm>
        <WsIconBtn
          @click="$_onRemoveStateModel(stateModelIndex)"
          name="icon-md-delete"
        ></WsIconBtn>
      </WsFlex>
    </WsCard>
    <WsBtn
      class="mt-20"
      @click="$_onModelAdd()"
    >新增 {{_label}}</WsBtn>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{ $t('submit') }}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `批次新增${this.label}`,
    };
  },
  methods: {
    async $_onInputStateModel($event, stateModelIndex) {
      const _states = [...this.states];
      _states[stateModelIndex] = $event;
      this.states = _states;
    },
    $_onRemoveStateModel(stateModelIndex) {
      this.states.splice(stateModelIndex, 1);
    },
    async $_onSubmit() {
      const _states = [...this.states];
      for (let i = 0; i < _states.length; i++) {
        _states[i] = {
          ...this.onForAllState,
          ..._states[i],
        };
        if (this.extendParams) {
          _states[i] = {
            ..._states[i],
            ...this.extendParams,
          };
        }
      }
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$axios.post(this._postUrl, {
          datas: _states,
        });
        this.$router.go(-1);
      } catch (error) {
        alert(this.$t(this.$o_o.$h.http.getErrorMessageFromRes(error)));
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onModelAdd() {
      this.states.push({});
    },
  },
  computed: {
    _postUrl() {
      return `/${this.modelName}/batch`;
    },
    _modelFields() {
      if (this.createShowFields) {
        const _modelFields = {};
        this.createShowFields.forEach((field) => {
          if (this.$store.state.stone_model[this.modelName].fields[field]) {
            _modelFields[field] =
              this.$store.state.stone_model[this.modelName].fields[field];
          }
        });
        return _modelFields;
      } else {
        return this.$store.state.stone_model[this.modelName].fields;
      }
    },
    _label() {
      return this.$store.state.stone_model[this.modelName].label;
    },
  },
  data() {
    return {
      onForAllState: {},
      states: [],
    };
  },
  props: {
    createShowFields: {},
    oneForAllFields: {},
    modelName: {},
    extendParams: {},
  },
};
</script>