const model = {
  fields: {
    xc_milestones: {
      type: 'belongsToMany',
      label: 'Milestone',
      modelName: 'xc_milestone',
    }
  },
  crudSetting: {
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "xc_milestones"
              ],
            },
          ],
        },
      ],
    }
  }
}
export default model