<template>
  <component
    :is="_rowComponent"
    class="ws-state-form w-full"
  >
    <component
      :is="_colComponent"
      v-for="(field, fieldKey) in _fields"
      :class="[`col-${field.col ? field.col : 12}`, `sm-col-${field.colSm ? field.sm : 12}`]"
      :key="fieldKey"
      :style="[
        { 'max-width': `${field.maxWidth}px` }
      ]"
    >
      <WsInfo
        v-if="field.info || info"
        v-bind="field"
        :parent="field.parentKey"
        :value="value[fieldKey]"
        :modelData="value"
        :warnText="$_getWarnText(field,value)"
      ></WsInfo>
      <WsGroupState
        v-else-if="field.groupstate"
        :value="value"
        @input="$_onGroupStateInput($event)"
        v-bind="field"
      />
      <WsState
        v-else
        :extendParams="$_getExtendParams(field, value)"
        :parent="field.parentKey"
        :arrange="arrange"
        :parentState="$_getParentState(fieldKey, fields)"
        :requiredFieldState="$_getParentState(fieldKey, fields)"
        :requiredField="field.requiredField"
        :displayLabel="field.displayLabel"
        :defaultValueOnEmpty="defaultValueOnEmpty[fieldKey]"
        :value="$_getValue(value, fieldKey)"
        :originValue="$_getOriginValue(resourceData, fieldKey)"
        :errorMessage="$_getErrorMessage(fieldKey)"
        @input="$_onInput(fieldKey, $event)"
        @form-input="$emit('form-input', $event)"
        :fullWidth="isFull"
        :disabled="$_getDisabled(field, value)"
        :stateData="value"
        :light="light"
        v-bind="field"
        :warnText="$_getWarnText(field,value)"
        :items="$_getItems(field, value)"
      />
    </component>
    <WsAlert
      v-if="typeof (errorMessages) === 'string'"
      ref="deleteAlert"
      title="錯誤"
      :description="$t(errorMessages)"
      @submit="$_onDeleteSubmit()"
      @close="$emit('error-popup-active', $event)"
      @open="$emit('error-popup-active', $event)"
    ></WsAlert>
  </component>
</template>

<script>
export default {
  data: () => ({}),

  methods: {
    $_getWarnText(field, value) {
      if (field.getWarnText) {
        return field.getWarnText(value);
      } else {
        return null;
      }
    },
    $_onGroupStateInput($event) {
      let _value = {
        ...this.value,
        ...$event,
      };
      this.$emit("input", _value);
    },
    $_getItems(field, value) {
      if (field.getItems) {
        return field.getItems(value);
      } else {
        return field.items;
      }
    },
    async $_onDeleteSubmit() {
      this.$refs.deleteAlert.close();
    },
    $_onError() {
      if (this.alertActive) {
        this.$refs.deleteAlert.open();
      }
    },
    $_getExtendParams(field, value) {
      let _params = {};
      if (field.getExtendParamsFromValue) {
        _params = {
          ..._params,
          ...field.getExtendParamsFromValue(value, this),
        };
      } else if (field.extendParams) {
        _params = field.extendParams;
      } else if (this.params) {
        _params = {
          ..._params,
          ...this.params,
        };
      }
      return _params;
    },
    $_getDisabled(field, value) {
      if (this.isDisabled) {
        return true;
      } else if (field.getDisabledFromValue) {
        return field.getDisabledFromValue(value);
      } else {
        return field.disabled;
      }
    },
    $_getValue(value, fieldKey) {
      const field = this.fields[fieldKey];
      if (!value) {
        return null;
      }
      if (field.type == "belongs-to-many-multi-layers") {
        const _value = {};
        field.layerFields.forEach((layerField) => {
          _value[layerField.stateKey] = value[layerField.stateKey];
        });
        return _value;
      } else if (field.type == "sort" && field.sortDataFrom) {
        if (value[field.sortDataFrom]?.length == value[fieldKey]?.length) {
          value[field.sortDataFrom] = value[fieldKey];
          return value[field.sortDataFrom];
        } else {
          value[fieldKey] = value[field.sortDataFrom];
          return value[fieldKey];
        }
      } else if (field.type == "address-group") {
        return {
          city: value[`${fieldKey}_city`],
          district: value[`${fieldKey}_district`],
          zip: value[`${fieldKey}_zip`],
        };
      } else {
        return value[fieldKey];
      }
    },
    async $_onInput(fieldKey, $event) {
      const field = this.fields[fieldKey];
      let _value = {
        ...this.value,
      };
      if (field.type == "belongs-to-many-multi-layers") {
        _value = {
          ..._value,
          ...$event,
        };
      } else if (field.type == "address-group") {
        _value = {
          ..._value,
          [`${fieldKey}_city`]: $event.city,
          [`${fieldKey}_district`]: $event.district,
          [`${fieldKey}_zip`]: $event.zip,
        };
      } else {
        _value[fieldKey] = $event;
      }
      if (field.setStateOnInput) {
        _value = await field.setStateOnInput(_value, $event, this);
      }
      this.$emit("input", _value);
    },
    $_getParentState(fieldKey, modedFields) {
      const field = this.$o_o.$h.state.getFieldByFieldKey(
        fieldKey,
        modedFields
      );
      if (field.parentKey) {
        return this.value[field.parentKey];
      } else if (
        field.requiredField &&
        this.value &&
        this.value[field.requiredField]
      ) {
        return this.value[field.requiredField];
      } else {
        return null;
      }
    },
    $_getOriginValue(resourceData, fieldKey) {
      if (!resourceData) {
        return null;
      } else {
        return resourceData[fieldKey];
      }
    },
    $_getErrorMessage(fieldKey) {
      if (this.errorMessages) {
        if (this.errorMessages[fieldKey]) {
          return this.errorMessages[fieldKey];
        } else if (typeof this.errorMessages === "string") {
          setTimeout(() => {
            this.$_onError();
          }, 100);
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    validate() {
      return this.$refs.form.validate();
    },
  },

  computed: {
    _fields() {
      const _fields = {};
      for (const fieldKey in this.fields) {
        const field = this.fields[fieldKey];
        if (field.displayCheckOnStateForm) {
          if (field.displayCheckOnStateForm(this.value)) {
            _fields[fieldKey] = field;
          }
        } else {
          _fields[fieldKey] = field;
        }
      }
      return _fields;
    },
    _rowComponent() {
      if (this.colMode == "col") {
        return "WsRow";
      } else if (this.colMode == "table") {
        return "tr";
      } else {
        return "div";
      }
    },
    _colComponent() {
      if (this.colMode == "col") {
        return "WsCol";
      } else if (this.colMode == "table") {
        return "td";
      } else {
        return "div";
      }
    },
  },

  props: {
    info: {
      type: Boolean,
    },
    defaultValueOnEmpty: {
      type: Object,
      default() {
        return {};
      },
    },
    fields: {
      type: Object,
    },
    value: {},
    errorMessages: {
      type: [Object, String],
      default: null,
    },
    resourceData: {
      type: Object,
      default: null,
    },
    arrange: {
      type: String,
    },
    isFull: {
      type: Boolean,
    },
    isDisabled: {
      type: Boolean,
    },
    colMode: {
      type: String,
      default: "col",
    },
    params: {
      type: Object,
    },
    light: {},
    alertActive: {
      type: Boolean,
      default: true,
    },
    // colIs:{
    //   type:String,
    //   default:'WsCol'
    // }
  },
};
</script>

<style></style>