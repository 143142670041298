<template>
  <WsMain>
    <WsModelFetchShow
      ref="WsModelFetchShow"
      modelName="xc_employee_hire"
      @fetched="$_onFetched($event)"
      :id="_id"
    >
      <template v-slot="{ modeldata }">
        <WsInfoForm
          :fields="_hireInfoFields"
          :value="modeldata"
        ></WsInfoForm>
        <WsWarnText :value="$_getWarnText(modeldata?.xc_employee_position_type)" />

        <WsModelFetchShow
          ref="WsModelFetchShow"
          modelName="xc_employee"
          :id="modeldata.xc_employee.id"
        >
          <template v-slot="{ modeldata }">
            <WsInfoForm
              :fields="_employeeInfoFields"
              :value="modeldata"
            ></WsInfoForm>
          </template>
        </WsModelFetchShow>
      </template>
    </WsModelFetchShow>
    <WsStateForm
      :fields="_stateFields"
      v-model="state"
    ></WsStateForm>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      xc_employee_hire: null,
      state: {},
    };
  },
  methods: {
    $_onFetched($event) {
      this.xc_employee_hire = $event;
    },
    $_getWarnText(xc_employee_position_type) {
      if (!xc_employee_position_type) {
        return;
      }
      if (
        this.state.work_allowance &&
        xc_employee_position_type.work_allowance &&
        this.state.work_allowance != xc_employee_position_type.work_allowance
      ) {
        return `${xc_employee_position_type.name}工作加給 ${this.$numeral(
          xc_employee_position_type.work_allowance
        ).format("$0,0")} 與設定不符`;
      } else {
        return;
      }
    },
    async $_onSubmit() {
      try {
        this.$store.dispatch("app/startPageLoading");
        const _postData = {};
        if (this.xc_employee_hire?.type == "stop") {
          _postData.employee_end_at = this.state.employee_end_at;
        } else if (this.xc_employee_hire?.type == "change") {
          _postData.basic_salary = this.state.basic_salary;
          _postData.meal_expenses = this.state.meal_expenses;
          _postData.work_allowance = this.state.work_allowance;
          _postData.transportation_allowance =
            this.state.transportation_allowance;
        } else if (this.xc_employee_hire?.type == "renew") {
          _postData.basic_salary = this.state.basic_salary;
          _postData.meal_expenses = this.state.meal_expenses;
          _postData.work_allowance = this.state.work_allowance;
          _postData.transportation_allowance =
            this.state.transportation_allowance;
        }
        await this.$axios.post(
          `/xc_employee_hire/${this._id}/apply`,
          _postData
        );
        this.$router.push(`/xc_employee_hire`);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _id() {
      return this.$route.params.id;
    },
    _employeeInfoFields() {
      const _employeeInfoFields = {};
      // if (this.xc_employee_hire?.type == "stop") {
      // } else if (this.xc_employee_hire?.type == "change") {
      if (this.xc_employee_hire?.type == "change") {
        _employeeInfoFields.basic_salary =
          this.$store.state.stone_model.xc_employee.fields.basic_salary;
        _employeeInfoFields.meal_expenses =
          this.$store.state.stone_model.xc_employee.fields.meal_expenses;
        _employeeInfoFields.work_allowance =
          this.$store.state.stone_model.xc_employee.fields.work_allowance;
        _employeeInfoFields.transportation_allowance =
          this.$store.state.stone_model.xc_employee.fields.transportation_allowance;
      } else if (this.xc_employee_hire?.type == "renew") {
        _employeeInfoFields.basic_salary =
          this.$store.state.stone_model.xc_employee.fields.basic_salary;
        _employeeInfoFields.meal_expenses =
          this.$store.state.stone_model.xc_employee.fields.meal_expenses;
        _employeeInfoFields.work_allowance =
          this.$store.state.stone_model.xc_employee.fields.work_allowance;
        _employeeInfoFields.transportation_allowance =
          this.$store.state.stone_model.xc_employee.fields.transportation_allowance;
      }
      return _employeeInfoFields;
    },
    _hireInfoFields() {
      return this.$store.state.stone_model.xc_employee_hire.fields;
      // const _hireInfoFields = {
      //   hire_note:
      //     this.$store.state.stone_model.xc_employee_hire.fields.hire_note,
      //   suggest_salary:
      //     this.$store.state.stone_model.xc_employee_hire.fields.suggest_salary,
      // };
      // // if (this.xc_employee_hire?.type == "stop") {
      // // } else if (this.xc_employee_hire?.type == "change") {
      // if (this.xc_employee_hire?.type == "change") {
      //   _hireInfoFields.xc_employee_position =
      //     this.$store.state.stone_model.xc_employee.fields.xc_employee_position;
      //   _hireInfoFields.xc_employee_position_type =
      //     this.$store.state.stone_model.xc_employee.fields.xc_employee_position_type;
      //   _hireInfoFields.xc_employee_department =
      //     this.$store.state.stone_model.xc_employee.fields.xc_employee_department;
      //   _hireInfoFields.xc_employee_type =
      //     this.$store.state.stone_model.xc_employee.fields.xc_employee_type;
      //   _hireInfoFields.xc_employee_working_schedule_setting =
      //     this.$store.state.stone_model.xc_employee.fields.xc_employee_working_schedule_setting;
      // }
      // // } else if (this.xc_employee_hire?.type == "renew") {
      // // }
      // return _hireInfoFields;
    },
    _stateFields() {
      const _stateFields = {
        basic_salary:
          this.$store.state.stone_model.xc_employee.fields.basic_salary,
        meal_expenses:
          this.$store.state.stone_model.xc_employee.fields.meal_expenses,
        work_allowance:
          this.$store.state.stone_model.xc_employee.fields.work_allowance,
        transportation_allowance:
          this.$store.state.stone_model.xc_employee.fields
            .transportation_allowance,
      };
      if (this.xc_employee_hire?.type == "stop") {
        delete _stateFields.basic_salary;
        delete _stateFields.meal_expenses;
        delete _stateFields.work_allowance;
        delete _stateFields.transportation_allowance;
        _stateFields.employee_end_at =
          this.$store.state.stone_model.xc_employee.fields.employee_end_at;
      }
      return _stateFields;
    },
  },
};
</script>

<style>
</style>