export default {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_milestone",
  label: "Milestone",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      labelInLocale: true,
      rules: 'required',
      col: 3,
    },
    xc_project: {
      type: 'belongsTo',
      label: '專案',
      textKey: "name",
      modelName: "xc_project",
      relationLink: true,
      col: 3,
    },
    start_at: {
      type: 'date',
      label: '開始日期',
      rules: 'required',
      col: 3,
    },
    end_at: {
      type: 'date',
      label: '結束日期',
      rules: 'required',
      col: 3,
    },
    creator: {
      type: "belongsTo",
      label: "建立人",
      textKey: "name",
      modelName: "admin",
      readonly: true,
      relationPopup: true,
      col: 3,
    },
    content: {
      type: 'textarea',
      label: '內容',
    },
  },
  crudSetting: {
    index: {
      showFields: ['name', 'xc_project', 'start_at', 'end_at', 'content'],
      creatable: true,
      updatable: true,
      deletable: true,
      // dialogCreate: true,
      // dialogUpdate: true,
      pageMode: true,
      filterSelects: {
        xc_project: {
          label: '專案',
          modelName: 'xc_project',
          type: "belongsTo",
          textKey: "name",
        }
      },
      extendOrderItems: [
        {
          value: 'start_at_desc',
          text: '開始日期新到舊',
          order_by: 'start_at',
          order_way: 'desc',
        },
        {
          value: 'start_at_asc',
          text: '開始日期舊到新',
          order_by: 'start_at',
          order_way: 'asc',
        },
        {
          value: 'end_at_desc',
          text: '結束日期新到舊',
          order_by: 'end_at',
          order_way: 'desc',
        },
        {
          value: 'end_at_asc',
          text: '結束日期舊到新',
          order_by: 'end_at',
          order_way: 'asc',
        },
      ],
      order: 'start_at_asc',
    },
    create: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ['name', 'xc_project', 'content'],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ['start_at', 'end_at'],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'name',
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ['name', 'xc_project', 'content'],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ['start_at', 'end_at'],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: ['name', 'xc_project', 'content'],
      rightFields: ['start_at', 'end_at'],
      titleKey: 'name',
    },
  }
}