<template>
  <div class="ws-logo">
    <img
      :src="_logo"
      alt=""
    >
  </div>
</template>

<script>
export default {
  computed: {
    _logo() {
      if (this.$config.theme.logo) {
        return this.$config.theme.logo;
      } else {
        return require("@/__stone/img/logo.svg");
      }
    },
  },
};
</script>

<style>
</style>