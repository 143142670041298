<template>
  <LayoutModelRead
    :id="_testing_case_id"
    v-bind="this.$store.state.stone_model.testing_case"
    :getUpdateUrl="$_getUpdateUrl"
  ></LayoutModelRead>
</template>

<script>
export default {
  computed: {
    _testing_case_id() {
      return this.$route.params.testing_case_id;
    },
  },
  methods: {
    $_getUpdateUrl(modeldata) {
      return `/xc_project/${this.$route.params.id}/testing_case/${modeldata.id}/update`;
    },
  },
};
</script>

<style>
</style>