<template>
  <div
    v-if="ready.query"
    class="ws-model-index"
  >
    <WsModelIndexTitleBar
      class="mb-4"
      v-if="label || creatable || importable || exportable||importformatdownloadable || _titleBarCustomBtns"
      v-bind="$props"
      :params="_params"
      :customBtns="_titleBarCustomBtns"
      @export="$_onExport()"
      @create="$_onCreate()"
      @import="$_onImport()"
      @importformatdownload="$_onImportformatdownload()"
      @customBtnClick="$_onCustomTitleAction($event)"
    />
    <div class="ws-model-index__top-state-section">
      <WsModelIndexTabs
        v-if="filterTabs"
        :items="filterTabs"
        :value="filter"
        @input="filter={...filter,...$event}"
        @ready="ready.tabs=true"
      />
      <WsModelIndexSelects
        class="d-xs-none"
        :fields="filterSelects"
        :value="filter"
        @input="filter={...filter,...$event}"
        :orderItems="_orderItems"
      />
    </div>
    <WsModelFetchIndex
      v-if="ready.tabs"
      class="ws-model-index__fetcher"
      ref="WsModelFetchIndex"
      :fetchUrl="_getUrl"
      :params="_params"
      v-slot="{modeldatas,meta,loading}"
    >
      <WsSheet class="ws-model-index__table-container">
        <WsModelIndexTable
          :modeldatas="modeldatas"
          v-bind="$props"
          :fields="_fields"
          @read="$_onRead($event)"
          @update="$_onUpdate($event)"
          @delete="$_onDelete($event)"
          @duplicate="$emit('duplicate',$event)"
          @custom-table-action="$_onCustomTableAction($event)"
          @update:modeldata="$_updateModelData($event)"
        />
        <WsModelIndexPaginate
          v-bind="meta"
          v-model="page"
        >
          <template #top>
            <WsLoading
              type="line"
              v-if="loading"
            />
          </template>
          <template #customBtns>
            <div class="d-none d-xs-block">
              <WsMoreBtn :items="_moreItems"></WsMoreBtn>
            </div>
            <div class="d-none d-xs-block">
              <WsIconBtn
                @click="$refs.WsModelDialogFilter.open()"
                name="icon-md-filter-list"
              ></WsIconBtn>
            </div>
          </template>
        </WsModelIndexPaginate>
      </WsSheet>
    </WsModelFetchIndex>
    <WsModelReadDialog
      ref="WsModelReadDialog"
      :fields="_fields"
      :getReadFetchUrl="getReadFetchUrl"
      :modelName="modelName"
    ></WsModelReadDialog>
    <WsModelDialogFilter
      ref="WsModelDialogFilter"
      :fields="filterSelects"
      :value="filter"
      @submit="filter=$event"
    ></WsModelDialogFilter>
    <WsModelIndexCreateDialog
      ref="WsModelIndexCreateDialog"
      v-bind="$props"
      :fields="_createFields"
      @complete="reFetch"
    />
    <WsModelIndexUpdateDialog
      ref="WsModelIndexUpdateDialog"
      v-bind="$props"
      :fields="_updateFields"
      @complete="updateModelData"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: {},
      page: 1,
      ready: {
        query: false,
        tabs: true,
        // query: false,
      },
      customFieldsFromApi: [],
    };
  },
  methods: {
    $_updateModelData($event) {
      this.$refs.WsModelFetchIndex.updateModeldata($event);
    },
    $_onCustomTitleAction($event) {
      this.$emit("custom-title-action", $event);
    },
    $_onRead($event) {
      if (this.dialogRead) {
        this.$refs.WsModelReadDialog.open($event);
      } else if (this.pageMode) {
        this.$router.push(this.$_getReadPageUrl($event));
      }
    },
    async $_onExport() {
      this.$store.dispatch("app/startPageLoading");
      try {
        const res = await this.$axios.get(
          `${this.modelName}/export/excel/signedurl`,
          {
            params: this._params,
          }
        );
        window.open(res.data, "_blank");
      } catch (error) {
        alert("匯出發生錯誤");
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onImportformatdownload() {
      this.$store.dispatch("app/startPageLoading");
      try {
        const res = await this.$axios.get(
          `${this.modelName}/importformatdownload/excel/signedurl`
        );
        window.open(res.data, "_blank");
      } catch (error) {
        alert("匯出發生錯誤");
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onCustomTableAction($event) {
      this.$emit($event.emit, $event.data);
      this.$emit("custom-table-action", $event);
    },
    reFetch() {
      this.$refs.WsModelFetchIndex.reFetch();
    },
    async $_onDeleteSubmit(data) {
      this.$store.dispatch("app/startPageLoading");
      try {
        let _deleteUrl;
        if (this.getDeleteSubmitUrl) {
          _deleteUrl = this.getDeleteSubmitUrl(data);
        } else {
          _deleteUrl = `/${this.modelName}/${data.id}`;
        }
        await this.$axios.delete(_deleteUrl);
        this.reFetch();
      } catch (error) {
        alert("刪除發生錯誤");
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    updateModelData($event) {
      this.$_updateModelData($event);
    },
    $_onDelete($event) {
      this.$store.dispatch("app/setAlert", {
        title: "確定刪除？",
        description: "",
        action: this.$_onDeleteSubmit,
        data: {
          ...$event,
        },
      });
    },
    $_onUpdate($event) {
      this.$refs.WsModelIndexUpdateDialog.open($event);
    },
    $_onCreate() {
      this.$refs.WsModelIndexCreateDialog.open();
    },
    $_onImport() {},
    $_updateQueryFromFilters() {
      const _fields = {
        ...this.filterSelects,
      };
      const _query = this.$o_o.$h.model.getQueryFromStates(
        this.filter,
        _fields
      );
      this.$router.replace({
        query: {
          ..._query,
          page: this.page,
        },
      });
    },
    async $_initCustomFields() {
      if (!this.hasCustomFieldsFromApi) {
        return;
      }
      const res = await this.$axios.get("custom_field", {
        params: {
          getall: 1,
          target: this.modelName,
        },
      });
      this.$_setCustomFieldsFromRes(res);
    },
    $_getReadPageUrl(item) {
      if (this.getReadUrl) {
        return this.getReadUrl(item);
      } else if (this.urlModelName) {
        return `/${this.urlModelName}/${item.id}`;
      } else {
        return `/${this.modelName}/${item.id}`;
      }
    },
    $_setCustomFieldsFromRes(res) {
      const _fields = {};
      res.data.data.forEach((item) => {
        _fields[item.code_name] = {
          label: item.name,
          type: item.type,
        };
      });
      this.customFieldsFromApi = _fields;
    },
    $_init() {
      this.$_initFilterFromQuery();
      this.$_initCustomFields();
    },
    $_initFilterFromQuery() {
      const _fields = {
        ...this.filterSelects,
      };
      this.filter = {
        ...this.filter,
        ...this.$o_o.$h.model.getStatesFromQuery(this.$route.query, _fields),
      };
      this.page = this.$route.query?.page ? this.$route.query.page : 1;
      this.ready.query = true;
    },
  },
  mounted() {
    this.$_init();
  },
  computed: {
    _moreItems() {
      const _moreItems = [];
      if (this.creatable) {
        _moreItems.push({
          title: "新增",
          onClick: this.$_onCreate,
        });
      }
      if (this.importable) {
        _moreItems.push({
          title: "匯入",
          onClick: this.$_onImport,
        });
      }
      if (this.exportable) {
        _moreItems.push({
          title: "匯出",
          onClick: this.$_onExport,
        });
      }
      return _moreItems;
    },
    _titleBarCustomBtns() {
      const _titleBarCustomBtns = [];
      if (!this.titleBarCustomBtns) {
        return [];
      }
      this.titleBarCustomBtns.forEach((titleBarCustomBtn) => {
        if (titleBarCustomBtn.requireScopes) {
          if (!this.$o_o.$h.auth.hasScope(titleBarCustomBtn.requireScopes)) {
            return;
          }
        }
        _titleBarCustomBtns.push(titleBarCustomBtn);
      });
      return _titleBarCustomBtns;
    },
    _createFields() {
      let _createFields = this.createFields
        ? { ...this.createFields }
        : { ...this._fields };
      _createFields = this.$o_o.$h.model.getCreateFields(_createFields);
      return _createFields;
    },
    _updateFields() {
      let _updateFields = this.updateFields
        ? { ...this.updateFields }
        : { ...this._fields };
      _updateFields = this.$o_o.$h.model.getUpdateFields(_updateFields);
      return _updateFields;
    },
    _fields() {
      return {
        ...this.$o_o.$h.model.getScopesFilteredFields(
          this.$store.state.auth.scopes,
          this.fields
        ),
        ...this.customFieldsFromApi,
      };
    },
    // @Q@
    _params() {
      return {
        ...this.params,
        ...this.paramsOnCrud,
        ...this.filter,
        ...this.$o_o.$h.model.getFormatedStates(
          this.filterSelects,
          this.filter
        ),
        page: this.page,
      };
    },
    _orderable() {
      if (this.orderable) {
        return true;
      } else if (this.orderLayerFields) {
        return true;
      } else {
        return false;
      }
    },
    _getUrl() {
      if (this.getUrl) {
        return this.getUrl;
      } else {
        return `/${this.modelName}`;
      }
    },
    _orderItems() {
      let _orderItems = [];
      if (this.orderBySq) {
        _orderItems = [
          ..._orderItems,
          {
            value: "sq",
            text: "排序設定",
            order_by: "sq",
            order_way: "asc",
          },
        ];
      }
      _orderItems = [
        ..._orderItems,
        ...[
          {
            value: "last_update",
            text: "更新時間近至遠",
            order_by: "updated_at",
            order_way: "desc",
          },
          {
            value: "last_created",
            text: "建立時間近至遠",
            order_by: "created_at",
            order_way: "desc",
          },
        ],
      ];
      if (this.extendOrderItems) {
        _orderItems = [...this.extendOrderItems, ..._orderItems];
      }
      return _orderItems;
    },
  },
  watch: {
    filter: {
      handler() {
        this.$_updateQueryFromFilters();
        this.page = 1;
      },
      deep: true,
    },
    // page: {
    //   handler() {
    //     this.$_updateQueryFromFilters();
    //   },
    // },
  },
  props: {
    actionWidth: {
      default: 140,
    },
    creatable: {},
    createUrl: {},
    customTableActions: {},
    deletable: {},
    dialogCreate: {},
    extendOrderItems: {},
    fields: {},
    filterSelects: {},
    filterTabs: {},
    getReadUrl: {},
    getUpdateUrl: {},
    importable: {},
    importformatdownloadable: {},
    exportable: {},
    label: {},
    modelName: {},
    orderLayerFields: {},
    orderable: {},
    orderBySq: {},
    pageMode: {
      default: true,
    },
    params: {},
    paramsOnCrud: {},
    readable: {},
    showFields: {},
    titleBarCustomBtns: {},
    updatable: {},
    urlModelName: {},
    getUrl: {},
    dialogRead: {},
    dialogUpdate: {},
    getDeleteSubmitUrl: {},
    hasCustomFieldsFromApi: {},
    updateFields: {},
    createFields: {},
    batchcreatable: {},
    fetchOnOpen: {
      type: Boolean,
      default: true,
    },
    inRowBtnRead: Boolean,
    inRowBtnUpdate: Boolean,
    inRowBtnDelete: Boolean,
    versionable: Boolean,
    getVersionUrl: {},
    getReadFetchUrl: {},
  },
};
</script>

<style>
</style>