const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_department',
  label: '員工部門',
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      autofocus: true,
      rules: 'required',
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'name',
      ],
      inRowBtnRead: true,
    },
  }
}

export default model