const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_work_overtime_settlement",
  label: "加班核發",
  fields: {
    start_at: {
      type: 'datetime',
      label: '核算起始時間',
    },
    end_at: {
      type: 'datetime',
      label: '核算結束時間',
    },
    source: {
      type: 'select',
      label: '核發來源',
      defaultValue: 'xc_employees',
      items: [
        {
          text: '指定計畫',
          value: 'xc_project',
        },
        {
          text: '指定人員',
          value: 'xc_employees',
        },
      ]
    },
    status: {
      type: 'select',
      label: '狀態',
      readonly: true,
      items: [
        {
          text: '等待付款',
          value: 'processing',
        },
        {
          text: '已核發',
          value: 'payed',
        },
      ]
    },
    // admins: {
    //   type: "belongsToMany",
    //   label: "指定負責人",
    //   modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    // },
    xc_employees: {
      type: "belongsToMany",
      label: "核發對象",
      textKey: "name",
      modelName: 'xc_employee'
    },
    payed_at: {
      type: 'datetime',
      label: '核發時間',
      readonly: true,
    },
  },
  crudSetting: {
    index: {
      showFields: [
        "start_at",
        "end_at",
        "source",
        "status",
        "payed_at",
      ],
      pageMode: true,
      creatable: true,
      updatable: false,
      customTableActions: [
        {
          icon: "icon-md-attach-money",
          tooltip: "付款完成",
          getPostUrl: (item) => {
            return `/xc_work_overtime_settlement/${item.id}/payed`
          },
          displayCheck: (item) => {
            if (item.status === 'processing') {
              return true
            }
          },
          postCheckText: '確定要標示付款完成嗎？',
          getPostCompleteData: (item) => {
            return {
              ...item,
              status: 'payed',
            }
          }
        },
      ],
    },
    read: {
      titleKey: 'name',
      updatable: false,
      leftFields: [
        "start_at",
        "end_at",
        "source",
        "statys",
        "xc_employees",
        "xc_project",
        "payed_at",
      ],
      childCruds: [
        {
          modelName: 'xc_work_overtime',
          titleBarCustomBtns: [
            {
              label: '匯出',
              getTo: (params) => {
                return `/print/xc_work_overtime?xc_work_overtime_settlement=${params.xc_work_overtime_settlement}`
              }
            }
          ],
          showFields: [
            "xc_employee",
            "hour",
            "work_overtime_type",
            "pay_amount",
            "adjusted_pay_amount",
          ],
          inRowBtnRead: true,
          creatable: false,
          deletable: false,
          updatable: true,
          showFieldsUpdate: [
            'adjusted_pay_amount'
          ]
        }
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (state) => {
                if (state.source === 'xc_project') {
                  return [
                    'start_at',
                    'end_at',
                    'source',
                    'xc_project',
                  ]
                } else if (state.source === 'xc_employees') {
                  return [
                    'start_at',
                    'end_at',
                    'source',
                    'xc_employees',
                  ]
                } else {
                  return [
                    'start_at',
                    'end_at',
                    'source',
                  ]
                }
              },
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (state) => {
                if (state.source === 'xc_project') {
                  return [
                    'start_at',
                    'end_at',
                    'source',
                    'xc_project',
                  ]
                } else if (state.source === 'xc_employees') {
                  return [
                    'start_at',
                    'end_at',
                    'source',
                    'xc_employees',
                  ]
                } else {
                  return [
                    'start_at',
                    'end_at',
                    'source',
                  ]
                }
              },
            },
          ],
        },
      ],
    }
  }
}

export default model