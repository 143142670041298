<template>
  <WsMain class="xs-pa-0">
    <WsCrud
      ref="WsCrud"
      :modelName="$store.state.stone_model.xc_leave_day.modelName"
      label="請假單"
      :fields="_fields"
      :customTableActions="customTableActions"
      :showFields="showFields"
      @status-ok="$_onStatusOk($event)"
      @status-no="$_onStatusNo($event)"
      :inRowBtnRead="true"
      :creatable="false"
      :updatable="false"
      :deletable="false"
      :filterTabs="filterTabs"
    >
    </WsCrud>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      filterTabs: [
        {
          text: "簽核中",
          value: "processing",
          params: {
            status: "processing",
          },
        },
        {
          text: "已核准",
          value: "approved",
          params: {
            status: "approved",
          },
        },
        {
          text: "不同意",
          value: "disapproved",
          params: {
            status: "disapproved",
          },
        },
        {
          text: "收回",
          value: "withdrawn",
          params: {
            status: "withdrawn",
          },
        },
        {
          text: "取消",
          value: "canceled",
          params: {
            status: "canceled",
          },
        },
        {
          text: "結算中",
          value: "settling",
          params: {
            status: "settling",
          },
        },
        {
          text: "已支付",
          value: "payed",
          params: {
            status: "payed",
          },
        },
      ],
      showFields: [
        "type",
        "xc_employee",
        "created_at",
        "start_at",
        "end_at",
        "days",
        "hours",
        "is_aboard",
        "status",
      ],
      customTableActions: [
        {
          text: "取消",
          onClick: (controller, modeldata, id, updateModeldata) => {
            controller.$store.dispatch("app/setAlert", {
              title: "確定取消？",
              description: "",
              action: async () => {
                try {
                  controller.$store.dispatch("app/startPageLoading");
                  await controller.$axios.post(`/xc_leave_day/${id}/cancel`);
                  controller.$store.dispatch("app/addSnack", "取消成功");
                  updateModeldata({
                    ...modeldata,
                    status: "canceled",
                  });
                } catch (error) {
                  console.log("error", error);
                  alert(
                    controller.$t(
                      controller.$o_o.$h.http.getErrorMessageFromRes(error)
                    )
                  );
                } finally {
                  controller.$store.dispatch("app/stopPageLoading");
                }
              },
            });
          },
          displayCheck(item) {
            return (
              item.status == "processing" ||
              item.status == "approved" ||
              item.status == "disapproved"
            );
          },
        },
        {
          text: "OK",
          displayCheck: (item) => {
            if (this.$config.wsmodule.xc_process) {
              return false;
            } else {
              return (
                item.status == "processing" || item.status == "disapproved"
              );
            }
          },
          onClick: async (controller, modeldata, id, updateModeldata) => {
            try {
              controller.$store.dispatch("app/startPageLoading");
              await controller.$o_o.$s._m.xc_leave_day.approve(id);
              updateModeldata({
                ...modeldata,
                status: "approved",
              });
            } catch (error) {
              console.error(error);
            } finally {
              controller.$store.dispatch("app/stopPageLoading");
            }
          },
        },
        {
          text: "No",
          displayCheck: (item) => {
            if (this.$config.wsmodule.xc_process) {
              return false;
            } else {
              return item.status == "processing" || item.status == "approved";
            }
          },
          onClick: async (controller, modeldata, id, updateModeldata) => {
            try {
              controller.$store.dispatch("app/startPageLoading");
              await controller.$o_o.$s._m.xc_leave_day.disapprove(id);
              updateModeldata({
                ...modeldata,
                status: "disapproved",
              });
            } catch (error) {
              console.error(error);
            } finally {
              controller.$store.dispatch("app/stopPageLoading");
            }
          },
        },
      ],
    };
  },
  methods: {
    async $_onStatusOk($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.approve($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "approved",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onStatusNo($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_leave_day.disapprove($event.item.id);
        this.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "disapproved",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _customTableActions() {
      if (this.$config.wsmodule.xc_process) {
        return [];
      } else {
        return [
          {
            emit: "status-ok",
            text: "OK",
            displayCheck: (item) => {
              return (
                item.status == "processing" || item.status == "disapproved"
              );
            },
          },
          {
            emit: "status-no",
            text: "No",
            displayCheck: (item) => {
              return item.status == "processing" || item.status == "approved";
            },
          },
        ];
      }
    },
    _fields() {
      return {
        ...this.$o_o.$h.model.getFieldsByKeys(
          this.$store.state.stone_model.xc_leave_day.fields,
          [
            "xc_employee",
            "created_at",
            "type",
            "start_at",
            "end_at",
            "status",
            "days",
            "hours",
            "is_aboard",
            "relate_files",
            "note",
          ]
        ),
      };
    },
  },
};
</script>