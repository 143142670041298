<template>
  <div v-if="value" class="ws-info-images">
    <div v-for="(item, index) in value" :key="index">
      <img
        v-if="$_checkFileType(item, 'image')"
        v-lazy="item"
      />
      <WsVideo 
        v-else-if="$_checkFileType(item, 'video')"
        :src="item"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    $_checkFileType(url, targetType) {
      let type = this.$o_o.$h.file.getFileType(url)
      if (type == targetType) {
        return true
      } else {
        return false
      }
    },
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
};
</script>