<template>
  <WsDialogUpdate
    ref="WsDialogUpdate"
    @close="$_onCancel()"
    @submit="$_onSubmit()"
    :contentHasPadding="false"
    title="選擇影片"
  >
    <template v-slot:content>
      <WsTabs
        v-model="focusTab"
        :items="_tabItems"
      />
      <FileSystemFilePickerVideoUpload
        v-if="focusTab == 'upload'"
        ref="FileSystemFilePickerVideoUpload"
        :storageTarget="storageTarget"
        @submit="$_onInputSubmit($event)"
      />
      <FileSystemFilePickerVideoPocket
        v-else-if="focusTab == 'pocket'"
        ref="FileSystemFilePickerVideoPocket"
        :storageTarget="storageTarget"
        :signed="signed"
        :multiple="multiple"
        @submit="$_onInputSubmit($event)"
      />
    </template>
  </WsDialogUpdate>
</template>

<script>
export default {
  data() {
    return {
      focusTab: 'upload',
    }
  },
  mounted() {
    this.focusTab = this._tabItems[0].value
  },
  methods: {
    $_onInputSubmit($event) {
      if (this.multiple) {
        this.$emit('submit', [$event])
      } else {
        this.$emit('submit', [$event])
      }
      this.close()
    },
    close() {
      this.$refs.WsDialogUpdate.close()
    },
    open() {
      this.$refs.WsDialogUpdate.open()
    },
    $_onDialogClose() {
      this.close()
    },
    $_onCancel() {
    },
    async $_onSubmit() {
      this.$store.dispatch('app/startPageLoading')
      try {
        if (this.focusTab == 'pocket') {
          this.$refs.FileSystemFilePickerVideoPocket.submit()
        } else if (this.focusTab == 'upload') {
          await this.$refs.FileSystemFilePickerVideoUpload.submit()
        }
      } finally {
        this.$store.dispatch('app/stopPageLoading')
      }
    },
  },
  computed: {
    _tabItems() {
      let _arr = []
      if (this.uploadable) {
        _arr.push({
          text: '上傳檔案',
          value: 'upload',
        })
      }
      if (this.pocketable) {
        _arr.push({
          text: '影片庫',
          value: 'pocket',
        })
      }
      return _arr
    },
  },
  props: {
    multiple: {
      type: Boolean,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    pocketable: {
      type: Boolean,
      default: true,
    },
    uploadable: {
      type: Boolean,
      default: true,
    },
    storageTarget: {
      default: 'general'
    }
  },
}
</script>
