// import config from '@/__config';

export default {
  getSystemSettingCmsFields(data, res) {
    let _res
    if (res) {
      _res = res
    } else {
      _res = {}
    }
    _res = {
      ..._res,
      // xc_customer_interact_due_notify_admins: {
      //   type: "belongsToMany",
      //   label: "客戶互動到期通知管理員",
      //   modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
      // },
      // xc_customer_interact_due_notify_days: {
      //   type: "number",
      //   label: "客戶互動到期通知天數",
      // },
      // xc_customer_notify_channel_id: {
      //   type: "text",
      //   label: "客戶通知頻道ID",
      // },
    }
    return _res
  },
  getSystemSettingCmsShowFields(data, res) {
    let _res
    if (res) {
      _res = res
    } else {
      _res = []
    }
    _res = [
      ..._res,
      // "xc_customer_interact_due_notify_admins",
      // "xc_customer_interact_due_notify_days",
      // "xc_customer_notify_channel_id",
    ]
    return _res
  }
}