<template>
  <WsMain class="pb-80">
    <ValidationObserver ref="form">
      <form
        ref="form"
        @submit.prevent
      >
        <WsStateForm
          :fields="fields"
          v-model="state"
        ></WsStateForm>
      </form>
    </ValidationObserver>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        password: {
          type: "password",
          label: "Current Password",
          rules: "required",
        },
        new_password: {
          vid: "New Password",
          type: "password",
          label: "New Password",
          rules: "required|uppercase|lowercase|digits|min:8|max:24",
          hint: "At least 8 characters, 1 uppercase, 1 lowercase, 1 digit",
        },
        new_password_confirmation: {
          type: "password",
          label: "New Password Confirmation",
          rules:
            "required|uppercase|lowercase|digits|min:8|max:24|confirmed:New Password",
        },
      },
      state: {
        ...this.$store.state.auth.currentUser,
      },
    };
  },
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$axios.patch("/auth/user/password", this.state);
        this.$store.dispatch("app/addSnack", "更新成功");
      } catch (error) {
        alert(this.$o_o.$h.http.getErrorMessageFromRes(error));
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
};
</script>

<style>
</style>