export default {
  options: {
    id: true,
    timestamp: true,
    tags: true,
  },
  modelName: "xc_task_template_group",
  label: "Task Template Group",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required',
      autofocus: true,
    },
    xc_task_templates: {
      type: 'belongsToMany',
      label: 'Task Template',
      modelName: 'xc_task_template',
      textKey: "name",
    },
  },
  crudSetting: {
    index: {
      liveSearching: false,
      pageMode: true,
      showFields: [
        'id',
        'name',
      ],
      infiniteScroll: false,
      inRowBtnRead: true,
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'xc_task_templates',
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'xc_task_templates',
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        'name',
        'xc_task_templates',
      ],
      titleKey: 'name',
    },
  }
}