<template>
  <div>
    <WsFlex>
      <WsSpacer></WsSpacer>
      <WsState
        @search="$_onSearch"
        :value="search"
        placeholder="搜尋"
        class="mt-8 mr-8"
        :style="[
          {
            width: '300px',
          },
        ]"
        type="search"
      />
    </WsFlex>
    <WsInfiniteScroll
      class="mt-8"
      ref="WsInfiniteScroll"
      v-slot="{ items }"
      fetchUrl="/drive/file"
      :params="_params"
      :bodyScroll="true"
    >
      <table class="w-full">
        <tr>
          <td class="pa-8">
            <WsDes>名稱</WsDes>
          </td>
          <td class="pa-8">
            <WsDes>檔案大小</WsDes>
          </td>
          <td class="pa-8">
            <WsDes>最後更新時間</WsDes>
          </td>
        </tr>
        <FileSystemDriveListFolder
          v-for="(item) in _folderItems"
          :key="`folder_${item.id}`"
          :folder="item"
          :urlPrefix="urlPrefix"
          :switchMode="switchMode"
          @delete="$_onDeleteFolder(item)"
          @switch="$_onFolderSwitch($event)"
        />
        <component
          :is="_listItemComponent"
          v-for="(item) in items"
          :key="`file_${item.id}`"
          v-bind="item"
          @delete="$_onDeleteFile(item)"
        ></component>
      </table>
    </WsInfiniteScroll>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMounted: false,
      layoutMode: "list",
      modeldatas: {
        folders: null,
      },
      extendFolders: [],
    };
  },
  methods: {
    $_onSearch($event) {
      if (!$event) {
        return;
      }
      this.$router.push("/drive/search?search=" + $event);
    },
    $_onFolderSwitch($event) {
      this.C_folder = $event.id;
    },
    $_onDeleteFolder(item) {
      const _modeldataIndex = this.modeldatas.folders.findIndex(
        (folder) => folder.id === item.id
      );

      if (_modeldataIndex > -1) {
        this.modeldatas.folders.splice(_modeldataIndex, 1);
        return;
      }

      const _extendFolderIndex = this.extendFolders.findIndex(
        (folder) => folder.id === item.id
      );

      if (_extendFolderIndex > -1) {
        this.extendFolders.splice(_extendFolderIndex, 1);
      }
    },
    $_onDeleteFile(item) {
      this.$refs.WsInfiniteScroll.removeItem(item.id);
    },
    async $_onSumitCreateFolder(state) {
      const res = await this.$axios.post("/drive/folder", {
        name: state.name,
        folder: this.C_folder,
      });
      this.extendFolders.push(res.data.data);
    },
  },
  computed: {
    _folderItems() {
      if (!this.modeldatas.folders) return [];
      return [...this.extendFolders, ...this.modeldatas.folders];
    },
    _params() {
      return {
        search: this.search,
      };
    },
    _listItemComponent() {
      if (this.layoutMode === "list") {
        return "FileSystemDriveListItem";
      } else {
        return "FileSystemDriveGridItem";
      }
    },
  },
  mounted() {},
  props: {
    switchMode: {
      type: String,
      default: "route",
    },
    folder: {
      type: [String, Number],
    },
    urlPrefix: String,
    search: String,
  },
  watch: {},
};
</script>

<style>
</style>