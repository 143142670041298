const model = {
  fields: {
    xc_employee_department: {
      type: 'belongsTo',
      label: '員工部門',
      textKey: 'name',
      modelName: 'xc_employee_department'
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'xc_employee_department'
      ]
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "xc_employee_department",
              ],
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "xc_employee_department",
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        "xc_employee_department",
      ],
    }
  }
}
export default model