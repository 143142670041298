export default {
  my_xc_work_overtime: {
    icon: 'icon-md-local-gas-station',
    title: '我的 加班單',
    link: '/my/xc_work_overtime',
  },
  xc_work_overtime: {
    icon: 'icon-md-local-gas-station',
    title: '加班管理',
    scopes: ['xc_work_overtime-admin', 'xc_work_overtime-subordinate-admin'],
    menu: [
      {
        title: '加班單',
        link: '/xc_work_overtime',
      },
      {
        title: '加班核發',
        link: '/xc_work_overtime_settlement',
        scopes: ['xc_work_overtime-admin']
      },
      {
        title: '加班費明細',
        link: '/xc_work_overtime_search',
        scopes: ['xc_work_overtime-admin']
      },
    ],
  },
}