<template>
  <div>
    <WsExcel
      :fields="_fields"
      :newIfBottom="true"
      v-model="state"
      :exportFilename="label"
      :exportable="_exportable"
      :importable="_importable"
      :clearable="true"
    ></WsExcel>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('送出')}}</WsBtn>
      </template>
    </WsBottomNav>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `批次新增${this.label}`,
    };
  },
  data() {
    return {
      state: [],
    };
  },
  methods: {
    async $_onSubmit() {
      console.log("$_onSubmit", this.state);
      try {
        this.$store.dispatch("app/startPageLoading");
        const _postData = {
          datas: this.state,
          ...this._extendPostData,
        };
        await this.$axios.post(this._postUrl, _postData);
        this.$router.go(-1);
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _postUrl() {
      if (this.postUrl) {
        return this.postUrl;
      } else {
        return `/${this.urlModelName}/batch`;
      }
    },
    _fields() {
      let _fields = {};
      if (this._batchFields) {
        for (const fieldKey of this._batchFields) {
          _fields[fieldKey] = this.fields[fieldKey];
        }
      } else {
        return this.fields;
      }
      return _fields;
    },
    _extendPostData() {
      let _extendPostData = {};
      if (this.extendPostData) {
        _extendPostData = {
          ..._extendPostData,
          ...this.extendPostData,
        };
      }
      if (
        this.crudSetting &&
        this.crudSetting[this.bindingKey] &&
        this.crudSetting[this.bindingKey].extendPostData
      ) {
        _extendPostData = {
          ..._extendPostData,
          ...this.crudSetting[this.bindingKey].extendPostData,
        };
      }
      return _extendPostData;
    },
    _value() {
      if (this.value) {
        return this.value;
      } else {
        return this.state;
      }
    },
    _params() {
      let _params = this.crudSetting[this.bindingKey].params;
      return _params;
    },
    _batchFields() {
      let _batchFields = this.crudSetting[this.bindingKey].batchFields;
      return _batchFields;
    },
    _exportable() {
      let _exportable = this.crudSetting[this.bindingKey].exportable;
      return _exportable;
    },
    _importable() {
      let _importable = this.crudSetting[this.bindingKey].importable;
      return _importable;
    },
  },
  props: {
    postUrl: {
      type: String,
    },
    bindingKey: {
      type: String,
      default: "batch",
    },
    modelName: {
      type: String,
    },
    urlModelName: {
      type: String,
    },
    label: {
      type: String,
    },
    fields: {
      type: Object,
    },
    crudSetting: {
      type: Object,
    },
    extendPostData: {
      type: Object,
    },
    batchFields: {},
  },
};
</script>