<template>
  <WsMain>
    <WsModelFetchShow
      ref="WsModelFetchShow"
      modelName="xc_employee_hire"
      :id="_id"
    >
      <template v-slot="{ modeldata }">
        <WsInfoForm
          :fields="_infoFields"
          :value="modeldata"
        ></WsInfoForm>
        <WsWarnText :value="$_getWarnText(modeldata.xc_employee_position_type)" />
      </template>
    </WsModelFetchShow>
    <WsStateForm
      :fields="_stateFields"
      v-model="state"
    ></WsStateForm>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {},
    };
  },
  methods: {
    $_getWarnText(xc_employee_position_type) {
      if (!xc_employee_position_type) {
        return;
      }
      if (
        this.state.work_allowance &&
        xc_employee_position_type.work_allowance &&
        this.state.work_allowance != xc_employee_position_type.work_allowance
      ) {
        return `${xc_employee_position_type.name}工作加給 ${this.$numeral(
          xc_employee_position_type.work_allowance
        ).format("$0,0")} 與設定不符`;
      } else {
        return;
      }
    },
    async $_onSubmit() {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$axios.post(
          `/xc_employee_hire/${this._id}/checkin`,
          this.state
        );
        this.$router.push(`/xc_employee_hire`);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _id() {
      return this.$route.params.id;
    },
    _infoFields() {
      return this.$store.state.stone_model.xc_employee_hire.fields;
      // return {
      //   hire_note:
      //     this.$store.state.stone_model.xc_employee_hire.fields.hire_note,
      //   suggest_salary:
      //     this.$store.state.stone_model.xc_employee_hire.fields.suggest_salary,
      // };
    },
    _stateFields() {
      return {
        basic_salary:
          this.$store.state.stone_model.xc_employee.fields.basic_salary,
        meal_expenses:
          this.$store.state.stone_model.xc_employee.fields.meal_expenses,
        work_allowance:
          this.$store.state.stone_model.xc_employee.fields.work_allowance,
        transportation_allowance:
          this.$store.state.stone_model.xc_employee.fields
            .transportation_allowance,
        employee_start_at:
          this.$store.state.stone_model.xc_employee.fields.employee_start_at,
        no: this.$store.state.stone_model.xc_employee.fields.no,
      };
    },
  },
};
</script>

<style>
</style>