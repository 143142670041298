<template>
  <component
    :is="_htmlTag"
    :to="to"
    @click="$emit('click',$event)"
    class="ws-icon"
    :class="[
      _iconClass,
      {button:_hasClickListener}
    ]"
    :style="[
      {'font-size':`${size}px`},
      {'color':color},
    ]"
  >
  </component>
</template>

<script>
export default {
  computed: {
    _htmlTag() {
      if (this.to) {
        return "RouterLink";
      } else {
        return "i";
      }
    },
    _hasClickListener() {
      return this.$listeners && this.$listeners.click;
    },
    _iconClass() {
      return this.name;
    },
  },
  props: {
    type: {
      type: String,
    },
    to: {
      type: String,
    },
    name: {
      type: String,
    },
    source: {
      type: String,
      default: "mdi",
    },
    color: {
      type: String,
    },
    size: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>