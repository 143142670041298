<template>
  <WsMain>
    <div>
      <WsVideo :src="url" />
    </div>
    <div>
      <WsBtn @click="$_onUploadClick()">上傳影片</WsBtn>
    </div>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="video/mp4,video/x-m4v,video/*"
    >
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      url: null,
      urls: [],
    }
  },
  methods: {
    async $_onChange($event) {
      this.file = $event.target.files[0];
      this.url = URL.createObjectURL(this.file);
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.$o_o.$s._m.file_system_video.upload(
          this.file,
          this.storageTarget,
        ).then((res) => {
          console.log('res',res)
          
          this.$emit('submit', res)
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
  },
  props: {
    storageTarget: {},
  },
}
</script>
