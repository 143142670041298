<template>
  <div>
    <WsActionApi
      v-if="_from_xc_employee_id"
      :fetchUrl="`/xc_employee/${_from_xc_employee_id}`"
      @fetch="$_onFetch"
    >
    </WsActionApi>
    <WsCreate
      v-if="xc_employee"
      ref="WsCreate"
      :modelName="$store.state.stone_model.xc_employee_hire.modelName"
      urlModelName="xc_employee_hire_change"
      label="聘用作業"
      :fields="_fields"
      :extendPostData="_extendPostData"
      completeRedirectUrl="/xc_employee_hire"
      :primary="_primary"
      v-model="state"
      :emitInput="true"
    >
      <template v-slot:beforeContent>
        <WsInfoForm
          class="mt-20"
          :fields="_xcEmployeeFields"
          :value="xc_employee"
        ></WsInfoForm>
      </template>
    </WsCreate>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xc_employee: null,
      state: {},
    };
  },
  methods: {
    $_onFetch($event) {
      this.state = {
        ...this.state,
        ...$event.data,
        xc_employee_status: this._from_xc_employee_status,
      };
      this.xc_employee = $event.data;
    },
    $_setState() {
      this.state = {
        ...this.state,
        type: this._type,
      };
    },
  },
  computed: {
    _primary() {
      if (this.state.type == "renew") {
        return [
          {
            type: "stateCard",
            floors: [
              {
                fields: [
                  "type",
                  "xc_employee_status",
                  "hire_note",
                  "suggest_salary",
                ],
              },
            ],
          },
        ];
      } else if (this.state.type == "stop") {
        return [
          {
            type: "stateCard",
            floors: [
              {
                fields: [
                  "type",
                  "xc_employee_status",
                  // "employee_end_at",
                  "hire_note",
                ],
              },
            ],
          },
        ];
      } else {
        return [
          {
            type: "stateCard",
            floors: [
              {
                fields: [
                  "type",
                  "xc_employee_status",
                  "xc_employee_position",
                  "xc_employee_position_type",
                  "xc_employee_department",
                  "xc_employee_type",
                  "xc_employee_working_schedule_setting",
                  "hire_note",
                  "suggest_salary",
                ],
              },
            ],
          },
        ];
      }
    },
    _type() {
      return this.$route.query.type;
    },
    _xcEmployeeShowFields() {
      if (this.state.type == "renew") {
        return [
          "name",
          "name_eng",
          "no",
          "email",
          "id_card_no",
          "gender",
          "nationality",
          "employee_start_at",
          "company_phone",
          "company_phone_ext",
          "native_place",
          "contact_address_city",
          "contact_address_district",
          "contact_address_zip",
          "contact_address_detail",
          "household_address_city",
          "household_address_district",
          "household_address_zip",
          "household_address_detail",
          "mobile",
          "contact_tel",
          "expertise",
          "research_range",
          "research_field",
          "autobiography",
          "autobiography_eng",
          "emergency_contacts",
          "family_members",
          "educations",
          "work_experiences",
          "exam_qualifications",
          "education_trainings",
          "publications",
          "articles",
          "research_projects_internal",
          "research_projects_external",
          "languages",
          "language_exams",
          "graduation_diplomas",
          "graduation_transcripts",
          "health_exams",
          "basic_salary",
          "meal_expenses",
          "work_allowance",
          "transportation_allowance",
          "approved_salary",
          "approved_salary_without_transportation",
        ];
      } else {
        return [
          "name",
          "name_eng",
          "no",
          "email",
          "id_card_no",
          "gender",
          "nationality",
          "employee_start_at",
          "company_phone",
          "company_phone_ext",
          "native_place",
          "contact_address_city",
          "contact_address_district",
          "contact_address_zip",
          "contact_address_detail",
          "household_address_city",
          "household_address_district",
          "household_address_zip",
          "household_address_detail",
          "mobile",
          "contact_tel",
          "expertise",
          "research_range",
          "research_field",
          "autobiography",
          "autobiography_eng",
          "emergency_contacts",
          "family_members",
          "educations",
          "work_experiences",
          "exam_qualifications",
          "education_trainings",
          "publications",
          "articles",
          "research_projects_internal",
          "research_projects_external",
          "languages",
          "language_exams",
          "graduation_diplomas",
          "graduation_transcripts",
          "health_exams",
          "basic_salary",
          "meal_expenses",
          "work_allowance",
          "transportation_allowance",
          "approved_salary",
          "approved_salary_without_transportation",
        ];
      }
    },
    _xcEmployeeFields() {
      return this.$o_o.$h.model.getFieldsByKeys(
        this.$store.state.stone_model.xc_employee.fields,
        this._xcEmployeeShowFields
      );
    },
    _extendPostData() {
      return {
        xc_employee: this._from_xc_employee_id,
      };
    },
    _from_xc_employee_id() {
      return this.$route.query.xc_employee;
    },
    _from_xc_employee_status() {
      return this.$route.query.xc_employee_status;
    },
    _fields() {
      const _fields = {
        ...this.$store.state.stone_model.xc_employee_hire.fields,
        type: {
          ...this.$store.state.stone_model.xc_employee_hire.fields.type,
          items: [
            {
              text: "續聘",
              value: "renew",
            },
            {
              text: "停聘",
              value: "stop",
            },
            {
              text: "改聘",
              value: "change",
            },
          ],
        },
      };
      return _fields;
    },
  },
  mounted() {
    this.$_setState();
  },
};
</script>