<template>
  <WsFlex
    v-if="modelData"
    class="pa-20 flex-full"
    flexDirection="column"
  >
    <WsAlert
      ref="versionAlert"
      :title="$t('sure_to_version')"
      :loading="loading.submit"
      @submit="$_onSubmit()"
    ></WsAlert>
    <WsBreadcrumbs
      v-if="hasBreadcrumbs&&!this.getUrl"
      :menu="_breadcrumbsItems"
    ></WsBreadcrumbs>
    <WsMenuView
      class="mt-16 flex-full"
      :menu="_menu"
    >
      <WsFlex>
        <WsBtn
          alert
          :outlined="false"
          :loading="loadingVersion"
          @click="$_onVersion()"
          :disabled="btnDisable"
        >{{$t('return to this version')}}</WsBtn>
        <!-- <WsBtn
          v-if="modelData.length > 1"
          class="ml-12"
          :to="_getVersionCompareUrl"
        >版本比較</WsBtn> -->
      </WsFlex>
      <WsReadSection
        :updatable="false"
        :deletable="false"
        :showBreadcrumbs="false"
        :id="_id"
        :modelName="modelName"
        :label="label"
        :fields="fields"
        :leftFields="leftFields"
        :rightFields="rightFields"
        :title="_versionDataId"
        v-model="_versionData"
      ></WsReadSection>
    </WsMenuView>
  </WsFlex>
</template>
<script>
export default {
  data: () => ({
    modelData: null,
    versionData: null,
    loadingVersion: false,
    btnDisable: true,
    loading: {
      submit: false,
    },
  }),
  methods: {
    async $_fetchModelData() {
      const res = await this.$axios.get(this._getUrl);
      this.modelData = res.data.data;
    },
    $_onVersion() {
      this.$refs.versionAlert.open();
    },
    async $_onSubmit() {
      this.loading.submit = true;
      try {
        await this.$axios.post(this._postUrl);
        this.$router.push(this._routerPushUrl);
      } catch (err) {
        alert(err);
      } finally {
        this.loading.submit = false;
        this.$refs.versionAlert.close();
      }
    },
    async $_onClickMenuItem($event) {
      if ($event.is_current) {
        this.btnDisable = true;
      } else {
        this.btnDisable = false;
      }
      const res = await this.$axios.get(`${this._getUrl}/${$event.id}`);
      this.versionData = res.data.data;
    },
    loadingStart() {
      this.loading.submit = true;
    },
    loadingStop() {
      this.loading.submit = false;
    },
  },
  mounted() {
    this.$_fetchModelData();
  },
  computed: {
    _getVersionCompareUrl() {
      if (this.getVersionCompareUrl) {
        return `${this.getVersionCompareUrl(
          this._id,
          this.$route.params
        )}?compare_1=${this._versionDataId}`;
      } else {
        return `/${this._urlModelName}/${this._id}/versioncompare?compare_1=${this._versionDataId}`;
      }
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      if (this.breadcrumbsItems) {
        return this.breadcrumbsItems;
      }
      _breadcrumbsItems.push({
        text: this.$t(this.label),
        to: `/${this._urlModelName}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this._id,
        to: `/${this._urlModelName}/${this._id}`,
        disabled: false,
      });
      if (this.titleKey && this.modelData && this.modelData[this.titleKey]) {
        _breadcrumbsItems.push({
          text: `${this.modelData[this.titleKey]} ${this.$t("version")}`,
          disabled: true,
        });
      } else {
        _breadcrumbsItems.push({
          text: this.$t("version"),
          disabled: true,
        });
      }
      return _breadcrumbsItems;
    },
    _id() {
      if (this.id) {
        return this.id;
      } else {
        return this.$route.params.id;
      }
    },
    _menu() {
      const _menu = [];
      if (this.modelData) {
        this.modelData.forEach((item) => {
          _menu.push({
            ...item,
            title: this.$moment(`${item.created_at}`).format(
              "YYYY-MM-DD hh:mm:ss "
            ),
            onClick: () => {
              this.$_onClickMenuItem(item);
            },
          });
        });
      }
      return _menu;
    },
    _versionDataId() {
      if (this.versionData) {
        return this.versionData.id;
      } else {
        return "-";
      }
    },
    _versionData() {
      if (this.versionData) {
        return this.versionData.content;
      } else {
        return {};
      }
    },
    _postUrl() {
      if (this.postUrl) {
        return this.postUrl;
      } else {
        return `/${this.modelName}/${this._id}/version/${this.versionData.id}/restore`;
      }
    },
    _getUrl() {
      if (this.getUrl) {
        return this.getUrl;
      } else {
        return `/${this.modelName}/${this._id}/version`;
      }
    },
    _routerPushUrl() {
      if (this.routerPushUrl) {
        return this.routerPushUrl;
      } else {
        return `/${this.modelName}/${this._id}`;
      }
    },
  },
  props: {
    hasBreadcrumbs: {
      type: Boolean,
      default: true,
    },
    titleKey: {
      type: String,
      default: "name",
    },
    postUrl: String,
    getUrl: String,
    routerPushUrl: String,
    breadcrumbsItems: Array,
    urlModelName: String,
    modelName: String,
    label: String,
    fields: Object,
    leftFields: Array,
    rightFields: Array,
    getVersionCompareUrl: {},
    id: {},
  },
};
</script>
<style>