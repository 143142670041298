<template>
  <WsMain>
    <FileSystemDriveSearch
      :search="$route.query.search"
      :key="`search_${$route.query.search}`"
    ></FileSystemDriveSearch>
  </WsMain>
</template>

<script>
export default {};
</script>

<style>
</style>