import config from '@/__config';

const model = {
  options: {
    id: true,
    timestamp: true,
    tags: true,
  },
  modelName: "xc_project",
  label: "計畫",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required',
      autofocus: true,
      col: 3,
    },
    short_name: {
      type: 'text',
      label: '計畫簡稱',
      rules: 'required',
      autofocus: true,
      col: 3,
    },
    status: {
      type: 'radio',
      label: '狀態',
      defaultValue: 'ing',
      items: [
        {
          value: 'ing',
          label: '進行中'
        },
        {
          value: 'complete',
          label: '結案'
        },
      ],
      col: 3,
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    no: {
      type: 'text',
      label: '代號',
      col: 3,
    },
    start_at: {
      type: 'date',
      label: '開始日期',
      col: 3,
    },
    end_at: {
      type: 'date',
      label: '結束日期',
      col: 3,
    },
    contract_member_salary: {
      type: 'number',
      label: '合約報支人事費',
      col: 3,
    },
    // payload: {
    //   type: 'payload',
    //   fields: {},
    // },
  },
  crudSetting: {
    index: {
      pageMode: true,
      showFields: [
        'name',
        'no',
        'status',
        'tags',
      ],
      inRowBtnRead: true,
      updatable: false,
      deletable: false,
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'short_name',
                'status',
                "no",
                "start_at",
                "end_at",
                "contract_member_salary",
                "remark",
                "payload",
                'tags',
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'name',
      getCompleteRedirectUrl: (id) => {
        return `/xc_project/${id}/info`
      },
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'short_name',
                'status',
                "no",
                "start_at",
                "end_at",
                "contract_member_salary",
                "remark",
                "payload",
                'tags',
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        'name',
        'short_name',
        "no",
        "start_at",
        "end_at",
        'status',
        "contract_member_salary",
        "remark",
        "payload",
        'created_at',
        'tags',
      ],
      titleKey: 'name',
    },
    batch: {
      exportable: true,
      importable: true,
      batchFields: [
        "no",
        "name",
        "start_at",
        "end_at",
        "contract_member_salary",
      ],
    }
  }
}

if (config.wsmodule.xc_project.fields?.slack_webhook_url) {
  model.fields.slack_webhook_url = {
    label: 'Slack Webhook URL',
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('slack_webhook_url');
  model.crudSetting.update.primary[0].floors[0].fields.push('slack_webhook_url');
  model.crudSetting.read.leftFields.push('slack_webhook_url');
}
if (config.wsmodule.xc_project.fields?.slack_team_id) {
  model.fields.slack_team_id = {
    type: 'text',
    label: 'Slack Team ID',
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('slack_team_id');
  model.crudSetting.update.primary[0].floors[0].fields.push('slack_team_id');
  model.crudSetting.read.leftFields.push('slack_team_id');
}
if (config.wsmodule.xc_project.fields?.slack_channel_id) {
  model.fields.slack_channel_id = {
    type: 'text',
    label: 'Slack Channel Id',
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('slack_channel_id');
  model.crudSetting.update.primary[0].floors[0].fields.push('slack_channel_id');
  model.crudSetting.read.leftFields.push('slack_channel_id');
}
if (config.wsmodule.xc_project.fields?.gitlab_project_ids) {
  model.fields.gitlab_project_ids = {
    type: 'text_array',
    label: 'Gitlab Project IDs',
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('gitlab_project_ids');
  model.crudSetting.update.primary[0].floors[0].fields.push('gitlab_project_ids');
  model.crudSetting.read.leftFields.push('gitlab_project_ids');
}
if (config.wsmodule.xc_project.fields?.invision) {
  model.fields.invision = {
    type: 'text',
    label: 'Invision 帳號',
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('invision');
  model.crudSetting.update.primary[0].floors[0].fields.push('invision');
  model.crudSetting.read.leftFields.push('invision');
}
if (config.wsmodule.xc_project.fields?.zeplin) {
  model.fields.zeplin = {
    type: 'text',
    label: 'Zeplin 帳號',
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('zeplin');
  model.crudSetting.update.primary[0].floors[0].fields.push('zeplin');
  model.crudSetting.read.leftFields.push('zeplin');
}
if (config.wsmodule.xc_project.fields?.gitlab) {
  model.fields.gitlab = {
    type: 'link',
    label: 'Gitlab 連結',
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('gitlab');
  model.crudSetting.update.primary[0].floors[0].fields.push('gitlab');
  model.crudSetting.read.leftFields.push('gitlab');
}
if (config.wsmodule.xc_project.fields?.github) {
  model.fields.github = {
    type: 'link',
    label: 'Github 連結',
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('github');
  model.crudSetting.update.primary[0].floors[0].fields.push('github');
  model.crudSetting.read.leftFields.push('github');
}
if (config.wsmodule.xc_project.fields?.google_drive) {
  model.fields.google_drive = {
    type: 'link',
    label: 'Google Drive 連結',
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('google_drive');
  model.crudSetting.update.primary[0].floors[0].fields.push('google_drive');
  model.crudSetting.read.leftFields.push('google_drive');
}

if (config.wsmodule.xc_project.fields?.links) {
  model.fields.links = {
    type: 'list',
    label: '相關連結',
    fields: {
      name: {
        type: 'text',
        label: '名稱',
        rules: 'required'
      },
      link: {
        type: 'link',
        label: '連結',
        rules: 'required'
      },
      remark: {
        type: 'textarea',
        label: '備註',
      },
    },
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('links');
  model.crudSetting.update.primary[0].floors[0].fields.push('links');
  model.crudSetting.read.leftFields.push('links');
}

if (config.wsmodule.xc_project.fields?.infos) {
  model.fields.infos = {
    type: 'list',
    label: '相關資訊',
    sm: 12,
    fields: {
      name: {
        type: 'text',
        label: '資訊名稱',
        rules: 'required'
      },
      remark: {
        type: 'textarea',
        label: '資訊內容',
      },
    },
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('infos');
  model.crudSetting.update.primary[0].floors[0].fields.push('infos');
  model.crudSetting.read.leftFields.push('infos');
}

if (config.wsmodule.xc_project.fields?.private_infos) {
  model.fields.private_infos = {
    type: 'list',
    label: '加密資訊',
    sm: 12,
    fields: {
      name: {
        type: 'text',
        label: '資訊名稱',
        rules: 'required'
      },
      remark: {
        type: 'textarea',
        label: '資訊內容',
      },
    },
  };
  model.crudSetting.create.primary[0].floors[0].fields.push('private_infos');
  model.crudSetting.update.primary[0].floors[0].fields.push('private_infos');
  model.crudSetting.read.leftFields.push('private_infos');
}

if (config.wsmodule.xc_project.fields?.status?.items) {
  model.fields.status.items = config.wsmodule.xc_project.fields?.status?.items
}


export default model;