import axios from 'axios'
export default {
  upload(
    file,
    storageTarget,
  ) {
    return new Promise((resolve, reject) => {
      const fileName = file.name
      const getUploadUrl = `/video/${storageTarget}/upload_url`
      axios
        .get(getUploadUrl, {
          params: {
            name: fileName,
          }
        })
        .then(res => {
          const uploadUrl = res.data
          axios
            .put(uploadUrl, file, {
              transformRequest: [(data, headers) => {
                delete headers.common.Authorization;
                return data
              }]
            })
            .finally(() => {
              const _url = uploadUrl.split("?")[0]
              this.complete(_url, storageTarget)
                .then((completeRes) => {
                  resolve(completeRes)
                })
            })
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  complete(
    url,
    storageTarget
  ) {
    return new Promise((resolve, reject) => {
      const completeUrl = `/video/${storageTarget}/upload_complete`
      axios
        .post(completeUrl, {
          url: url
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}