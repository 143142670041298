export default [
  {
    text: '人力資源 管理者',
    value: 'xc_employee-admin'
  },
  {
    text: '下屬人員查詢',
    value: 'xc_employee_under-admin'
  },
  {
    text: '下屬聘用作業管理',
    value: 'xc_employee_hire_under-admin'
  },
]