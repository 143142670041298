<template>
  <WsMain>
    <WsAnalyticsGroup :items="analytics"></WsAnalyticsGroup>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      analytics: [
        {
          title: "當前應收金額",
          type: "value",
          format: "$0,0",
          fetchUrl: "/calc/value/xc_service_order_stage/price_receiable",
          fields: {},
        },
        {
          title: "未開發票、Invoice金額",
          type: "value",
          format: "$0,0",
          fetchUrl: "/calc/value/xc_service_order_stage/price_uninvoice",
          fields: {},
        },
        {
          title: "當前應收金額",
          type: "sum_mass",
          format: "$0,0",
          fetchUrl: "calc/sum_mass/xc_service_order_stage/price_receiable",
          fields: {},
        },
        {
          title: "當前應收金額",
          type: "trending_mass",
          fetchUrl: "calc/trending_mass/xc_service_order_stage/price_receiable",
        },
        {
          title: "訂單總金額",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_service_order/price",
          fields: {
            owner: {
              // type: "belongsTo",
              // modelName: "admin",
              ...this.$store.state.stone_model.xc_service_order.fields.owner,
              label: null,
              placeholder: "歸屬人",
            },
            deal_date: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "訂單總金額",
          type: "trending",
          fetchUrl: "/calc/trending/xc_service_order/price",
          fields: {
            deal_date: {
              type: "date-range",
              defaultValue: [
                this.$moment()
                  .add(-6, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "預估開立發票金額 (未開發票未到款)",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_service_order_stage/price",
          params: {
            invoice_date: "null",
            transfer_date: "null",
          },
          fields: {
            expect_invoice_date: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "實際開立發票金額",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_service_order_stage/price",
          fields: {
            invoice_date: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "預估開立發票金額 (未開發票未到款)",
          type: "trending",
          fetchUrl: "/calc/trending/xc_service_order_stage/price",
          params: {
            invoice_date: "null",
            transfer_date: "null",
          },
          fields: {
            expect_invoice_date: {
              type: "date-range",
              defaultValue: [
                this.$moment()
                  .add(-6, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "實際開立發票金額",
          type: "trending",
          fetchUrl: "/calc/trending/xc_service_order_stage/price",
          fields: {
            invoice_date: {
              type: "date-range",
              defaultValue: [
                this.$moment()
                  .add(-6, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "預估到款且未到款金額",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_service_order_stage/price",
          params: {
            transfer_date: "null",
          },
          fields: {
            expect_transfer_date: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "實際到款金額",
          type: "sum",
          format: "$0,0",
          fetchUrl: "/calc/sum/xc_service_order_stage/price",
          fields: {
            transfer_date: {
              type: "date-range",
              defaultValue: [
                this.$moment().startOf("month").format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "預估到款且未到款金額",
          type: "trending",
          fetchUrl: "/calc/trending/xc_service_order_stage/price",
          params: {
            transfer_date: "null",
          },
          fields: {
            expect_transfer_date: {
              type: "date-range",
              defaultValue: [
                this.$moment()
                  .add(-6, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
        {
          title: "實際到款金額",
          type: "trending",
          fetchUrl: "/calc/trending/xc_service_order_stage/price",
          fields: {
            transfer_date: {
              type: "date-range",
              defaultValue: [
                this.$moment()
                  .add(-6, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD"),
                this.$moment().endOf("month").format("YYYY-MM-DD"),
              ],
            },
          },
        },
      ],
    };
  },
  computed: {
    // _analytics(){
    //   const _analytices =
    // }
  },
};
</script>