const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'testing_case_template',
  label: 'TestCase Template',
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required',
    },
    des: {
      type: 'textarea',
      label: '描述',
    },
    condition: {
      type: 'textarea',
      label: '前置條件',
    },
    priority: {
      type: 'radio',
      label: '優先度',
      defaultValue: 'p1',
      items: [
        { value: 'p1', label: 'p1' },
        { value: 'p2', label: 'p2' },
        { value: 'p3', label: 'p3' },
      ]
    },
    testing_case_template_items: {
      type: 'hasMany',
      label: '測試步驟 Template',
      modelName: 'testing_case_template_item',
    }
  },
  crudSetting: {
    index: {
      showFields: [
        'name',
      ],
      inRowBtnRead: true,
      pageMode: true,
    },
    read: {
      titleKey: 'name',
      cmsLog: true,
      leftFields: [
        "testing_case_template_items",
      ],
      rightFields: [
        "name",
        "des",
        "condition",
        "priority",
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "testing_case_template_items",
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "priority",
                "name",
                "des",
                "condition",
              ]
            }
          ]
        }
      ]
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "testing_case_template_items",
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "priority",
                "name",
                "des",
                "condition",
              ]
            }
          ]
        }
      ]
    }
  }
}

export default model