const model = {
  fields: {
    xc_milestone_required: {
      type: 'switch',
      label: 'Milestone 必填',
      defaultValue: true,
    },
    xc_milestone: {
      type: 'belongsTo',
      label: 'Milestone',
      modelName: 'xc_milestone',
      relationLink: true,
      getRules: (item) => {
        if (item && item.xc_milestone_required) {
          return 'required'
        } else {
          return ''
        }
      },
      getExtendParamsFromValue: (value) => {
        if (value.xc_project) {
          return {
            xc_project: value.xc_project.id
          }
        } else {
          return {
            xc_project: 0
          }
        }
      }
    },
  },
  crudSetting: {
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'xc_milestone_required',
                'xc_milestone',
              ],
            },
          ],
        },
      ],
    }
  }
}
export default model