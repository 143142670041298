<template>
  <div>
    <WsVersion
      :id="_id"
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :fields="fields"
      v-bind="crudSetting.version"
      :value="_value"
      :emitInput="emitInput"
      @input="$_onInput($event)"
      @modelDataInit="$emit('modelDataInit',$event)"
      @submit="$emit('submit',$event)"
    >
    </WsVersion>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `${this.label}${this.$t("version")}`,
    };
  },
  data() {
    return {
      state: null,
    };
  },
  methods: {
    $_onInput($event) {
      this.state = $event;
      this.$emit("input", $event);
    },
  },
  computed: {
    _id() {
      if (this.id) {
        return this.id;
      } else {
        return this.$route.params.id;
      }
    },
    _value() {
      if (this.value) {
        return this.value;
      } else {
        return this.state;
      }
    },
  },
  props: {
    modelName: {
      type: String,
    },
    urlModelName: {
      type: String,
    },
    label: {
      type: String,
    },
    fields: {
      type: Object,
    },
    crudSetting: {
      type: Object,
    },
    value: {
      type: Object,
    },
    emitInput: {
      type: Boolean,
      default: false,
    },
    getVersionCompareUrl: {},
    id: {},
  },
};
</script>