<template>
  <div>
    <LayoutModelVersionCompare
      v-bind="this.$store.state.stone_model.xc_project_spec"
      :label="this.$store.state.stone_model.xc_project_spec.label"
    ></LayoutModelVersionCompare>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `專案規格版本 | ${this.xc_project.name}`,
    };
  },
  props: {
    xc_project: {},
  },
};
</script>

<style></style>
