<template>
  <WsMain>
    <WsModelMyIndex
      label="我的加班單"
      ref="WsModelMyIndex"
      :modelName="$store.state.stone_model.xc_work_overtime.modelName"
      :fields="$store.state.stone_model.xc_work_overtime.fields"
      :showFields="$store.state.stone_model.xc_work_overtime.crudSetting.index_my.showFields"
      :inRowBtnRead="true"
      :updatable="false"
      :deletable="false"
      :dialogRead="true"
      :dialogCreate="false"
      :pageMode="true"
      :customTableActions="customTableActions"
      @item-cancel="$_onCancel($event)"
      @item-withdraw="$_onWithdraw($event)"
    >
      <template v-slot:WsReadDialogAfterContent="{ item }">
        <XcProcessEventCommentEasyTable
          v-if="item.xc_process_event"
          :id="item.xc_process_event.id"
        />
      </template>
    </WsModelMyIndex>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      showFields: [
        "xc_employee",
        "created_at",
        "start_at",
        "hour",
        "status",
        "work_overtime_type",
      ],
      customTableActions: [
        {
          text: "收回",
          onClick: (controller, modeldata, id, updateModeldata) => {
            controller.$store.dispatch("app/setAlert", {
              title: "確定收回？",
              description: "",
              action: async () => {
                try {
                  controller.$store.dispatch("app/startPageLoading");
                  await controller.$axios.post(
                    `my/xc_work_overtime/${id}/withdraw`
                  );
                  controller.$store.dispatch("app/addSnack", "收回成功");
                  updateModeldata({
                    ...modeldata,
                    status: "withdrawn",
                  });
                } catch (error) {
                  console.log("error", error);
                  alert(
                    controller.$t(
                      controller.$o_o.$h.http.getErrorMessageFromRes(error)
                    )
                  );
                } finally {
                  controller.$store.dispatch("app/stopPageLoading");
                }
              },
            });
          },
          displayCheck(item) {
            return item.status == "processing";
          },
        },
      ],
    };
  },
  methods: {
    async $_onCancel($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_work_overtime.my_cancel($event.item.id);
        this.$refs.WsModelMyIndex.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "canceled",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onWithdraw($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_work_overtime.my_withdraw($event.item.id);
        this.$refs.WsModelMyIndex.$refs.WsCrud.updateModelData({
          ...$event.item,
          status: "withdrawn",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _fields() {
      return {
        ...this.$o_o.$h.model.getFieldsByKeys(
          this.$store.state.stone_model.xc_work_overtime.fields,
          ["id", "hours", "days", "note", "status", "is_aboard"]
        ),
      };
    },
  },
};
</script>
