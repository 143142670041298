<template>
  <div>
    <FileSystemDriveIndex
      v-if="_folderId"
      :key="_folderId"
      :urlPrefix="`/xc_project/${xc_project.id}`"
      :folder="_folderId"
    ></FileSystemDriveIndex>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `檔案 | ${this.xc_project.name}`,
    };
  },
  computed: {
    _folderId() {
      return this.xc_project?.folder?.id;
    },
  },
  data() {
    return {};
  },
  props: {
    xc_project: {},
  },
};
</script>

<style></style>
