export default {
  getXcProjectMenu(data, res) {
    const ctx = data.ctx
    if (ctx.$o_o.$h.auth.hasScope(['xc_password_manager-admin'])) {
      res.splice(1, 0, {
        title: "帳號密碼",
        link: `/xc_project/${data.id}/xc_password_pair`,
      });
    }
    return res
  },
  getXcProjectExtendRoutes(data, res) {
    let _routes = []
    if (res) {
      _routes = res
    }
    _routes.push(...[
      {
        path: 'xc_password_pair',
        name: 'XcPasswordPair',
        component: () => import('@/__modules/xc_password_manager/extend_modules/xc_project/views/XcProject/XcPasswordPair.vue'),
        meta: {
          middleware: 'auth',
        },
      },
    ])
    return _routes
  }
}