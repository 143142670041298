// import i18n from '@/i18n';
export default {
  my: {},
  admin: {
    calc: true,
    admin_blur: true,
    admin_role: true,
    fields: {
      github: true,
      gitlab: true,
      ssh_public_key: true,
      identity_id: true,
      mobile: true,
      working_email: true,
      personal_email: true,
      identity_card_front: true,
      identity_card_back: true,
      health_card_front: true,
      relate_infos: true,
      email_verified_at: true,
      color: true,
    },
  },
  punch: true,
  tag: true,
  xc_task: true,
  xc_project: {
    my_xc_project: true,
    fields: {
      slack_webhook_url: true,
      slack_team_id: true,
      slack_channel_id: true,
      gitlab_project_ids: true,
      invision: true,
      zeplin: true,
      gitlab: true,
      github: true,
      google_drive: true,
      links: true,
      infos: true,
      private_infos: true,
    },
    xc_project_member: {
      fields: {
        admin: true,
        scopes: true,
        // xc_employee: true,
        // role_name: true,
        // start_at: true,
        // end_at: true,
        // month_days_json: true,
      }
    },
    file: true
  },
  xc_billing_statement: true,
  system: true,
  cms_log: {},
  system_setting: {
    // foooter: {
    // WsFooterType: false
    // }
  },
  file_system: {
    drive: true
  },
  quotation: {},
  slack: {},
  system_event: {},
  admin_event: {},
  xc_work_overtime: {},
  xc_customer: {},
  xc_service_order: {},
  hr_recruit: {},
  xc_financial: {},
  xc_meeting: {},
  calendar: {},
  xc_rfq: {},
  xc_friend: {},
  xc_out_resource: {},
  gitlab: {},
  xc_milestone: {},
  xc_leave_day: true,
  xc_wfh: true,
  xc_substitute: {},
  xc_project_spec: {},
  xc_employee: {},
  testing: {},
  xc_password_manager: {},
};
