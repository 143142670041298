<template>
  <WsModelMySimpleIndex
    ref="WsModelMySimpleIndex"
    label="我的加班單"
    modelName="xc_work_overtime"
    :filterTabs="filterTabs"
    :showFields="showFields"
    :readDialogFields="_readDialogFields"
    :customTableActions="customTableActions"
    @item-cancel="$_onCancel($event)"
    @item-withdraw="$_onWithdraw($event)"
  >
    <template v-slot:WsReadDialogAfterContent="{ item }">
      <XcProcessEventCommentEasyTable
        v-if="item.xc_process_event"
        :id="item.xc_process_event.id"
      />
    </template>
  </WsModelMySimpleIndex>
</template>

<script>
export default {
  data() {
    return {
      model: this.$store.state.stone_model.xc_work_overtime,
      customTableActions: [
        // {
        //   text: "取消",
        //   emit: "item-cancel",
        //   displayCheck(item) {
        //     return item.status == "approved";
        //   },
        // },
        {
          text: "收回",
          onClick: (controller, modeldata, id, updateModeldata) => {
            controller.$store.dispatch("app/setAlert", {
              title: "確定收回？",
              description: "",
              action: async () => {
                try {
                  controller.$store.dispatch("app/startPageLoading");
                  await controller.$axios.post(
                    `my/xc_work_overtime/${id}/withdraw`
                  );
                  controller.$store.dispatch("app/addSnack", "收回成功");
                  updateModeldata({
                    ...modeldata,
                    status: "withdrawn",
                  });
                } catch (error) {
                  console.log("error", error);
                  alert(
                    controller.$t(
                      controller.$o_o.$h.http.getErrorMessageFromRes(error)
                    )
                  );
                } finally {
                  controller.$store.dispatch("app/stopPageLoading");
                }
              },
            });
          },
          displayCheck(item) {
            return item.status == "processing";
          },
        },
      ],
      filterTabs: [
        {
          text: "簽核中",
          value: 1,
          params: {
            status: "processing",
          },
        },
        {
          text: "已核准",
          value: 2,
          params: {
            status: "approved",
          },
        },
        {
          text: "不同意",
          value: 3,
          params: {
            status: "disapproved",
          },
        },
        {
          text: "取消",
          value: 5,
          params: {
            status: "canceled",
          },
        },
      ],
      showFields: [
        "created_at",
        "work_overtime_type",
        "remark",
        "start_at",
        "end_at",
        "hour",
        "xc_project",
        "status",
      ],
      readDialogFields: [
        "id",
        "exchange_for_compensatory_leave",
        "admin",
        "created_at",
        "work_overtime_type",
        "remark",
        "start_at",
        "end_at",
        "hour",
        "xc_project",
        "status",
      ],
    };
  },
  computed: {
    _readDialogFields() {
      let _readDialogFields = [];
      for (const key in this.model.fields) {
        _readDialogFields.push(key);
      }
      return _readDialogFields;
    },
  },
  methods: {
    async $_onCancel($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_work_overtime.my_cancel($event.item.id);
        this.$refs.WsModelMySimpleIndex.$_fetchModelData();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    async $_onWithdraw($event) {
      try {
        this.$store.dispatch("app/startPageLoading");
        await this.$o_o.$s._m.xc_work_overtime.my_withdraw($event.item.id);
        this.$refs.WsModelMySimpleIndex.$_fetchModelData();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
};
</script>
