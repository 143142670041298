<template>
  <WsRow>
    <WsCol cols="4">
      <WsInfo
        v-model="value.city"
        :items="cityItems"
        label="縣市"
        type="select"
      />
    </WsCol>
    <WsCol cols="4">
      <WsInfo
        v-model="value.district"
        :items="districtItems"
        label="行政區"
        type="select"
      />
    </WsCol>
    <WsCol cols="4">
      <WsInfo
        v-model="value.zip"
        label="郵遞區號"
        type="text"
      />
    </WsCol>
  </WsRow>
</template>

<script>
export default {
  data() {
    return {
      cityItems: [],
      districtItems: [],
    }
  },
  methods: {
    async $_fetchCityItems() {
      try {
        const res = await this.$axios.get('/city')
        this.cityItems = res.data.data.map(item => ({
          text: item.name,
          value: item.id,
        }))
      } catch (error) {
        console.error(error)
      }
    },
    async $_fetchDistrictItems() {
      try {
        const res = await this.$axios.get('/district', {
          params: {
            city: this.value.city
          }
        })
        this.districtItems = res.data.data.map(item => ({
          text: item.name,
          value: item.id,
          zip: item.zip,
        }))
      } catch (error) {
        console.error(error)
      }
    },
    async $_init() {
      try {
        await this.$_fetchCityItems()
        if (this.value?.city) {
          this.$_fetchDistrictItems()
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
  mounted() {
    this.$_init()
  },
  props: {
    value: Object,
    abel: String,
  },
}
</script>