import * as changeCase from 'change-case';
import H_Auth from '@/__stone/helpers/auth';
import $c from '@/__config'
import Spare_Topic from '@/__stone/spare/topic'
import H_Module from './module'
import Vue from 'vue'

export default {
  getCmsMenu(topicKey) {
    let menus
    if (topicKey) {
      menus = this.getMenusOfTopic(topicKey)
    } else {
      menus = this.getMenus()
    }
    menus = this.getScopesFilteredMenu(menus)
    return menus
  },
  getCmsTopics() {
    if (!$c.menu.topic) {
      return []
    }
    let _topics = {}
    const topics = this.getTopics()
    for (const topicKey in topics) {
      if (!this.checkCmsTopicDisplay(topicKey)) {
        continue
      }
      _topics[topicKey] = topics[topicKey]
    }
    if ($c.menu.topic.sq) {
      const _sqTopics = {}
      $c.menu.topic.sq.forEach(sqKey => {
        if (_topics[sqKey]) {
          _sqTopics[sqKey] = _topics[sqKey]
        }
      });
      _topics = _sqTopics
    }
    return _topics
  },
  checkCmsTopicDisplay(topicKey) {
    let menus = this.getMenusOfTopic(topicKey)
    menus = this.getScopesFilteredMenu(menus)
    if (!Object.keys(menus).length) {
      return false
    } {
      return true
    }
  },
  getMenusOfTopic(topicKey) {
    let _menus = {}
    const _topic = this.getTopic(topicKey)
    const allMenus = this.getMenus()
    if (_topic?.menuItems) {
      _topic.menuItems.forEach(menuKey => {
        if (allMenus[menuKey]) {
          if (
            allMenus[menuKey].menu &&
            $c.wsmodule[menuKey]?.expandMenu
          ) {
            allMenus[menuKey].menu.forEach((menuItemKey) => {
              const _item = allMenus[menuKey].menu.find((e) => {
                return e.name == menuItemKey.name;
              });
              _menus[menuItemKey.name] = _item;
            });
          } else {
            _menus[menuKey] = allMenus[menuKey];
          }
        }
      });
    }
    if (_topic?.modules) {
      _topic.modules.forEach(moduleKey => {
        const menuModule = this.getModuleMenu(moduleKey)
        _menus = this.addModuleMenuInModuleMenus(_menus, menuModule)
      });
    }
    return _menus
  },
  getModuleMenu(moduleKey) {
    let _menu = {}
    const _modulesJson = this.getModulesJson()[moduleKey]
    if (_modulesJson) {
      _menu = {
        ..._menu,
        ..._modulesJson.menu
      }
    }
    _menu = this.mergeModuleExtendModulesMenu(_menu, moduleKey)
    return _menu
  },
  getMenus() {
    if ($c.menu.custom) {
      return $c.menu.custom
    }
    let _menu = {
      ...this.getModuleMenus(),
    }
    if ($c.menu.extend) {
      _menu = {
        ..._menu,
        ...$c.menu.extend
      }
    }
    return _menu
  },
  getScopesFilteredMenu(menu) {
    let _menu = {};
    for (const menuKey in menu) {
      const menuItem = menu[menuKey]
      if (H_Auth.hasScope(menuItem.scopes)) {
        const _menuItem = { ...menuItem };
        if (menuItem.menu) {
          _menuItem.menu = this.getScopesFilteredMenu(menuItem.menu);
        }
        _menu = {
          ..._menu,
          [menuKey]: _menuItem
        }
      }
    }
    return _menu;
  },
  getMenuOption(module) {
    const _option = this[`getMenuOption${changeCase.pascalCase(module)}`]();
    if (_option) {
      return _option;
    } else {
      return {};
    }
  },
  getMenuFromCloneTypes(cloneTypes) {
    const menu = [];
    for (const key in cloneTypes) {
      menu.push({
        title: cloneTypes[key].title,
        link: `/${key}`,
      });
    }
    return menu;
  },
  getModulesContext() {
    try {
      return require.context('@' + '/__modules/', true, /\.js/i);
    } catch (error) {
      return
    }
  },
  addModuleMenuInModuleMenus(moduleMenus, moduleMenu) {
    let _menus = {
      ...moduleMenus
    }

    for (const menuKey in moduleMenu) {
      if (_menus[menuKey]) {
        _menus = {
          ..._menus,
          [menuKey]: this.mergeMenuIntoExistMenu(moduleMenu[menuKey], _menus[menuKey])
        }
      } else {
        _menus = {
          ..._menus,
          [menuKey]: moduleMenu[menuKey]
        }
      }
    }
    return _menus
  },
  mergeMenuIntoExistMenu(menu, existMenu) {
    const _existMenu = {
      ...existMenu
    }
    if (menu.icon) {
      _existMenu.icon = menu.icon
    }
    if (menu.title) {
      _existMenu.title = menu.title
    }
    if (menu.menu) {
      if (!_existMenu.menu) {
        _existMenu.menu = []
      }
      _existMenu.menu = [
        ..._existMenu.menu,
        ...menu.menu
      ]
    }
    if (menu.scopes) {
      if (!_existMenu.scopes) {
        _existMenu.scopes = []
      }
      _existMenu.scopes = [
        ..._existMenu.scopes,
        ...menu.scopes
      ]
    }
    return _existMenu
  },
  getModuleMenus() {
    let _menus = {}
    for (const moduleKey in $c.wsmodule) {
      if (!Vue.prototype.$config.wsmodule[moduleKey]) {
        continue
      }
      const menuModule = this.getModuleMenu(moduleKey)
      _menus = this.addModuleMenuInModuleMenus(_menus, menuModule)
    }
    if ($c.menu.menu?.fields) {
      for (const menuKey in $c.menu.menu.fields) {
        if (_menus[menuKey]) {
          const field = $c.menu.menu.fields[menuKey]
          for (const fieldKey in field) {
            if (fieldKey == 'menu') {
              _menus[menuKey][fieldKey].forEach((menuItem, i) => {
                for (const key in field[fieldKey]) {
                  if (menuItem.link == key) {
                    _menus[menuKey][fieldKey][i] = {
                      ..._menus[menuKey][fieldKey][i],
                      ...field[fieldKey][key]
                    }
                  }
                }
              })
            } else {
              _menus[menuKey][fieldKey] = field[fieldKey]
            }
          }
        }
      }
    }
    return _menus
  },
  mergeModuleExtendModulesMenu(menu, moduleKey) {
    let _menu = {
      ...menu
    }
    const modules = H_Module.getModulesContext()
    if (!modules) {
      return
    }
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const _moduleKey = keyArr[1]
      if (!$c.wsmodule[_moduleKey]) {
        return
      }
      if (
        keyArr[2] == 'extend_modules' &&
        keyArr[4] == 'menu.js'
      ) {
        const extendModuleName = keyArr[3]
        if (extendModuleName == moduleKey) {
          _menu = this.addModuleMenuInModuleMenus(_menu, modules(modulePath).default)
        }
      }
    });
    return _menu
  },
  getModulesJson() {
    const modules = H_Module.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (!moduleFiles[key][keyArr[2]]) {
        moduleFiles[key][keyArr[2]] = {}
      }
      if (keyArr[2] == 'menu.js') {
        moduleFiles[key].menu = modules(modulePath).default
      }
    });
    return moduleFiles
  },
  getTopics() {
    if (!$c.menu.topic) {
      return {}
    }
    const _topics = {}
    for (const topicKey in Spare_Topic) {
      const topic = Spare_Topic[topicKey]
      const _modules = []
      if (topic.modules) {
        topic.modules.forEach(item => {
          if ($c.wsmodule[item]) {
            _modules.push(item)
          }
        });
      }
      _topics[topicKey] = {
        ...topic,
        modules: _modules
      }
    }
    if ($c.menu.topic.fields) {
      for (const topicKey in $c.menu.topic.fields) {
        const field = $c.menu.topic.fields[topicKey]
        if (_topics[topicKey]) {
          for (const fieldKey in field) {
            _topics[topicKey][fieldKey] = field[fieldKey]
          }
        }
      }
    }
    if ($c.menu.topic.extend) {
      for (const topicKey in $c.menu.topic.extend) {
        const _exTopic = $c.menu.topic.extend[topicKey]
        if (!_topics[topicKey]) {
          _topics[topicKey] = {
            ..._exTopic,
          }
        } else {
          let _modules = _topics[topicKey].modules ? _topics[topicKey].modules : []
          let _menuItems = _topics[topicKey].menuItems ? _topics[topicKey].menuItems : []
          if (_exTopic.modules) {
            _modules = [...new Set([..._modules, ..._exTopic.modules])]
          }
          if (_exTopic.menuItems) {
            _menuItems = [...new Set([..._menuItems, ..._exTopic.menuItems])]
          }
          _topics[topicKey].title = _exTopic.title
          _topics[topicKey].modules = _modules
          _topics[topicKey].menuItems = _menuItems
        }
      }
    }
    return _topics
  },
  getTopic(topicKey) {
    if (!$c.menu.topic) {
      return
    }
    const _topics = this.getTopics()
    return _topics[topicKey]
  },
  getTopicTitle(topicKey) {
    if (!$c.menu.topic) {
      return
    }
    const _topic = this.getTopic(topicKey)
    return _topic ? _topic.title : null
  },
  getFirstTopicKey() {
    const topics = this.getTopics()
    const _arr = Object.keys(topics)
    return _arr.length ? _arr[0] : null
  },
  getInitTopicKey() {
    if (!$c.menu.topic) {
      return
    }
    const topic = localStorage.getItem('topic');
    if (topic) {
      return topic
    }
    return this.getFirstTopicKey()
  },
  getTopicFilterMenu(menu, topic) {
    if (!topic) {
      return menu
    }
    return menu
  },
  getFilteredTopics(topics) {
    let _topics = {}
    for (const topicKey in topics) {
      const _menu = this.getMenu(topicKey)
      if (!Object.keys(_menu).length) {
        continue
      }
      _topics = {
        ..._topics,
        [topicKey]: topics[topicKey]
      }
    }
    return _topics
  }
};
