<template>
  <div>
    <WsUpdate
      ref="WsUpdate"
      :modelName="$store.state.stone_model.xc_employee_hire.modelName"
      urlModelName="xc_employee_hire_new"
      label="新聘作業"
      :fields="$store.state.stone_model.xc_employee_hire.fields"
      :extendPostData="extendPostData"
      completeRedirectUrl="/xc_employee_hire"
      :primary="primary"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      extendPostData: {
        type: "new",
        xc_employee_status: "working_new",
      },
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "name",
                "name_eng",
                "no",
                "email",
                "xc_employee_position",
                "xc_employee_position_type",
                "xc_employee_department",
                "xc_employee_type",
                "xc_employee_working_schedule_setting",
                "id_card_no",
                "gender",
                "nationality",
                // "employee_start_at",
                "expect_employee_start_at",
                "expect_employee_end_at",
                "mobile",
                "contact_tel",
                // "company_phone",
                // "company_phone_ext",
                "native_place",
                "contact_address_city",
                "contact_address_district",
                "contact_address_zip",
                "contact_address_detail",
                "household_address_city",
                "household_address_district",
                "household_address_zip",
                "household_address_detail",
                "expertise",
                "research_range",
                "research_field",
                // "autobiography",
                // "autobiography_eng",
                "emergency_contacts",
                "family_members",
                "educations",
                "work_experiences",
                "exam_qualifications",
                "education_trainings",
                // "publications",
                // "articles",
                // "research_projects_internal",
                // "research_projects_external",
                "languages",
                "language_exams",
                // "graduation_diplomas",
                // "graduation_transcripts",
                // "health_exams",
                // "basic_salary",
                // "meal_expenses",
                // "work_allowance",
                // "transportation_allowance",
                // "approved_salary",
                // "approved_salary_without_transportation",
                "next_action_days_after_new_hire",
                // "create_admin_after_approval",
                // "create_admin_default_password",
                "hire_note",
                "suggest_salary",
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    _fields() {
      const _fields = {
        ...this.$store.state.stone_model.xc_employee_hire.fields,
      };
      delete _fields.xc_employee;
      delete _fields.xc_employee_status;
      delete _fields.employee_end_at;
      return _fields;
    },
  },
};
</script>