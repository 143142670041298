import i18n from '@/i18n'

export default {
  getErrorMessageFromRes(error) {
    let errorMessage = ''
    if (
      error.response &&
      error.response.data
    ) {
      if (error.response.data.message) {
        if (
          error.response.data.message == 'The custom no has already been taken.'
        ) {
          errorMessage = '此活動自訂編號已被使用';
          return errorMessage;
        } else {
          errorMessage = i18n.t(error.response.data.message);
        }
      }
      if (error.response.data.errors) {
        for (const key in error.response.data.errors) {
          errorMessage += `\n${i18n.t(error.response.data.errors[key])}`
        }
      }
    } else {
      errorMessage = 'error'
    }
    return errorMessage
  },
  checkErrorMatchExp(error, exp) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      return error.response.data.message.match(exp)
    }
    return false
  }
}