import config from '@/__config'
import moment from 'moment'

export default {
  getValueFromFieldAndFormValue(field, formValue, key) {
    if (field.type == 'date-range-or-not') {
      if (formValue.start_date && formValue.end_date) {
        return {
          startDate: formValue.start_date,
          endDate: formValue.end_date,
        }
      } else {
        return {}
      }
    } else if (field.type == "address-group") {
      return {
        city: formValue[`${key}_city`],
        district: formValue[`${key}_district`],
        zip: formValue[`${key}_zip`],
      }
    } else {
      return formValue[key]
    }
  },
  getFormatedStates(fields, resourceData, postEncode) {
    const _fStates = {};
    for (let fieldKey in fields) {
      const field = fields[fieldKey];
      if (field.readonly) {
        continue;
      }
      if (field.type == 'belongs-to-many-multi-layers' && resourceData) {
        field.layerFields.forEach((layerField) => {
          _fStates[layerField.stateKey] = [];
          if (resourceData[layerField.stateKey]) {
            resourceData[layerField.stateKey].forEach((resourceDataItem) => {
              _fStates[layerField.stateKey].push(resourceDataItem.id);
            });
          }
        });
      } else if (resourceData && resourceData[fieldKey] != undefined) {
        if (field.type == 'date') {
          _fStates[fieldKey] = moment(resourceData[fieldKey]).format(
            'YYYY-MM-DD',
          );
        } else if (field.type == 'date-range-or-not' && resourceData) {
          _fStates.start_date = resourceData[fieldKey].startDate
          _fStates.end_date = resourceData[fieldKey].endDate
        } else if (field.type == 'datetime') {
          _fStates[fieldKey] = moment(resourceData[fieldKey])
        } else if (field.type == 'time') {
          _fStates[fieldKey] = moment(resourceData[fieldKey]);
        } else if (field.type == 'belongsTo') {
          _fStates[fieldKey] = resourceData[fieldKey].id;
        } else if (field.type == 'system_audio') {
          _fStates[fieldKey] = resourceData[fieldKey].id;
        } else if (
          field.type == 'file_image' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          _fStates[fieldKey] = btoa(resourceData[fieldKey]);
        } else if (
          field.type == 'file_images' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'file_files' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'image' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          _fStates[fieldKey] = btoa(resourceData[fieldKey]);
        } else if (
          field.type == 'file' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          _fStates[fieldKey] = btoa(resourceData[fieldKey]);
        } else if (
          field.type == 'link' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          resourceData
          _fStates[fieldKey] = btoa(resourceData[fieldKey]);
        } else if (
          field.type == 'list' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'images' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'files' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'payload' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (
          field.type == 'wasaintro' &&
          config.general.postEncode &&
          !!postEncode
        ) {
          const aa = JSON.stringify(resourceData[fieldKey]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else if (field.type == 'pocket_image') {
          _fStates[fieldKey] = resourceData[fieldKey].id;
        } else if (field.type == 'pocket_file') {
          _fStates[fieldKey] = resourceData[fieldKey].id;
        } else if (field.type == 'belongsToMany') {
          const _value = [];
          const valueKey = field.valueKey ? field.valueKey : 'id';
          resourceData[fieldKey].forEach((valueItem) => {
            _value.push(valueItem[valueKey]);
          });
          _fStates[fieldKey] = _value;
        } else if (field.type == 'date-range') {
          _fStates[fieldKey] = resourceData[fieldKey].join(',');
        } else if (field.type == 'section_content') {
          const _content = {};
          for (const key in resourceData[fieldKey]) {
            const contentItem = resourceData[fieldKey][key];
            _content[key] = {
              id: contentItem.id,
              content: contentItem.content,
            };
          }
          const aa = JSON.stringify(resourceData[_content]);
          const bb = btoa(unescape(encodeURIComponent(aa)));
          _fStates[fieldKey] = bb;
        } else {
          _fStates[fieldKey] = resourceData[fieldKey];
        }
      } else if (field.defaultValue !== undefined) {
        _fStates[fieldKey] = field.defaultValue;
        continue;
      } else if (field.type == 'form') {
        const _resourceData = resourceData ? resourceData[fieldKey] : null;
        _fStates[fieldKey] = this.getFormatedStates(
          field.fields,
          _resourceData,
        );
      } else if (_fStates[fieldKey]) {
        continue;
      } else {
        _fStates[fieldKey] = null;
      }
    }
    return _fStates;
  },
  getValueByFieldKey(fieldKey, valueItem) {
    const keyArr = fieldKey.split('.');
    let target = valueItem;
    for (let i = 0; i < keyArr.length; i++) {
      const keyItem = keyArr[i];
      let targetKey = keyItem;
      if (targetKey == 'locales' && keyArr.length > 1) {
        continue;
      }
      if (targetKey == 'shop_order_shop_product' && keyArr.length > 1) {
        continue;
      }
      if (target && target[targetKey] !== undefined) {
        target = target[targetKey];
      } else {
        return null;
      }
    }
    return target;
  },
  getFieldByFieldKey(fieldKey, fields) {
    const keyArr = fieldKey.split('.');
    let target = fields;
    for (let i = 0; i < keyArr.length; i++) {
      const keyItem = keyArr[i];
      if (i == 0) {
        if (target[keyItem] !== undefined) {
          target = target[keyItem];
        } else {
          return null;
        }
      } else {
        if (target.fields[keyItem] !== undefined) {
          target = target.fields[keyItem];
        } else {
          return null;
        }
      }
    }
    return target;
  },
  getValueFromFields(fields, value) {
    const _value = {};
    for (let fieldKey in fields) {
      const _field = fields[fieldKey];
      if (_field.type == 'belongs-to-many-multi-layers') {
        _value[fieldKey] = {};
        _field.layerFields.forEach((layerField) => {
          _value[fieldKey][layerField.stateKey] = [
            ...value[layerField.stateKey],
          ];
        });
      } else if (_field.type == 'date-range-or-not') {
        if (value.start_date && value.end_date) {
          _value[fieldKey] = {
            startDate: value.start_date,
            endDate: value.end_date,
          }
        } else {
          _value[fieldKey] = {}
        }
      } else if (value[fieldKey] != undefined) {
        _value[fieldKey] = value[fieldKey];
      }
    }
    return _value;
  },
  defaultValueSet(state, fields) {
    for (let fieldKey in fields) {
      const _field = fields[fieldKey];
      if (state[fieldKey] != undefined) {
        continue;
      } else if (_field.defaultValue != undefined) {
        state[fieldKey] = _field.defaultValue;
      }
    }
  },
}