import M_xc_employee from '@/__modules/xc_employee/models/xc_employee'

const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_employee_hire',
  label: '聘用作業',
  fields: {
    type: {
      type: 'select',
      label: '作業類型',
      items: [
        {
          text: '新聘',
          value: 'new',
        },
        {
          text: '續聘',
          value: 'renew',
        },
        {
          text: '停職',
          value: 'stop'
        },
        {
          text: '改職',
          value: 'change'
        },
      ]
    },
    name: M_xc_employee.fields.name,
    name_eng: M_xc_employee.fields.name_eng,
    // no: M_xc_employee.fields.no,
    email: M_xc_employee.fields.email,
    xc_employee_position_type: M_xc_employee.fields.xc_employee_position_type,
    xc_employee_position: M_xc_employee.fields.xc_employee_position,
    xc_employee_department: M_xc_employee.fields.xc_employee_department,
    xc_employee_type: M_xc_employee.fields.xc_employee_type,
    xc_employee_working_schedule_setting: M_xc_employee.fields.xc_employee_working_schedule_setting,
    status: {
      type: 'select',
      label: '狀態',
      items: [
        {
          text: '簽核中',
          value: 'processing'
        },
        {
          text: '已核准',
          value: 'approved'
        },
        {
          text: '不同意',
          value: 'disapproved'
        },
        {
          text: '收回',
          value: 'withdrawn'
        },
        {
          text: '取消',
          value: 'canceled'
        },
        {
          text: '已報到',
          value: 'checkin'
        },
        {
          text: '已生效',
          value: 'applied'
        },
      ]
    },
    xc_employee_status: {
      ...M_xc_employee.fields.status,
      label: '員工狀態',
    },
    employee_start_at: M_xc_employee.fields.employee_start_at,
    employee_end_at: M_xc_employee.fields.employee_end_at,
    expect_employee_start_at: {
      type: 'date',
      label: '聘期開始日期',
    },
    expect_employee_end_at: {
      type: 'date',
      label: '聘期結束日期',
    },
    // company_phone: M_xc_employee.fields.company_phone,
    // company_phone_ext: M_xc_employee.fields.company_phone_ext,
    nationality: M_xc_employee.fields.nationality,
    id_card_no: M_xc_employee.fields.id_card_no,
    gender: M_xc_employee.fields.gender,
    contact_address_city: M_xc_employee.fields.contact_address_city,
    contact_address_district: M_xc_employee.fields.contact_address_district,
    contact_address_zip: M_xc_employee.fields.contact_address_zip,
    contact_address_detail: M_xc_employee.fields.contact_address_detail,
    household_address_city: M_xc_employee.fields.household_address_city,
    household_address_district: M_xc_employee.fields.household_address_district,
    household_address_zip: M_xc_employee.fields.household_address_zip,
    household_address_detail: M_xc_employee.fields.household_address_detail,
    mobile: {
      ...M_xc_employee.fields.mobile,
      col: 6
    },
    contact_tel: {
      ...M_xc_employee.fields.contact_tel,
      col: 6
    },
    expertise: M_xc_employee.fields.expertise,
    emergency_contacts: M_xc_employee.fields.emergency_contacts,
    family_members: M_xc_employee.fields.family_members,
    educations: M_xc_employee.fields.educations,
    work_experiences: M_xc_employee.fields.work_experiences,
    exam_qualifications: M_xc_employee.fields.exam_qualifications,
    education_trainings: M_xc_employee.fields.education_trainings,
    languages: M_xc_employee.fields.languages,
    language_exams: M_xc_employee.fields.language_exams,
    xc_employee: {
      type: 'belongsTo',
      label: '員工',
      modelName: 'xc_employee',
    },
    // basic_salary: M_xc_employee.fields.basic_salary,
    // meal_expenses: M_xc_employee.fields.meal_expenses,
    // work_allowance: M_xc_employee.fields.work_allowance,
    // transportation_allowance: M_xc_employee.fields.transportation_allowance,
    // approved_salary: M_xc_employee.fields.approved_salary,
    // approved_salary_without_transportation: M_xc_employee.fields.approved_salary_without_transportation,
    // additional_health_insurance_people_counts: M_xc_employee.fields.additional_health_insurance_people_counts,
    manage_xc_employee: {
      type: 'belongsTo',
      label: '負責人',
      modelName: 'xc_employee',
    },
    next_action_days_after_new_hire: {
      type: 'number',
      label: '新聘後幾日提醒後續動作',
      defaultValue: 60
    },
    // create_admin_after_approval: {
    //   type: 'switch',
    //   defaultValue: 1,
    //   label: '簽核通過後是否建立後台帳號',
    // },
    // create_admin_default_password: {
    //   type: 'text',
    //   label: '簽核通過後建立後台帳號的預設密碼',
    //   rules: 'required',
    //   stateDisplayCheck(item) {
    //     if (item.create_admin_after_approval) {
    //       return true
    //     } else {
    //       return false
    //     }
    //   },
    // },
    hire_note: {
      type: 'textarea',
      label: '意見'
    },
    suggest_salary: {
      type: 'currency',
      label: '建議核定薪資',
    },
  },
  crudSetting: {
    index: {
      creatable: false,
      updatable: false,
      filterTabs: [
        {
          text: "全部",
          value: 'all',
          params: {
          },
        },
        {
          text: "新聘",
          value: 'working_new',
          params: {
            xc_employee_status: 'working_new',
          },
        },
        {
          text: "續聘",
          value: 'working_renew',
          params: {
            xc_employee_status: 'working_renew',
          },
        },
      ],
      showFields: [
        'no',
        'name',
        'created_at',
        'status',
        'xc_employee_status',
      ],
      titleBarCustomBtns: [
        {
          label: '新聘',
          to: '/xc_employee_hire_new/create',
        },
      ],
      inRowBtnRead: true,
      pageMode: true,
    },
    read: {
      titleKey: 'name',
      leftFields: [
        'name',
      ],
    },
  }
}

export default model