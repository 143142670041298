<template>
  <div v-if="isMounted">
    <input
      v-show="false"
      @change="$_onUploadChange($event)"
      type="file"
      ref="fileInput"
      accept="*/*"
      :multiple="true"
    >
    <WsFlex>
      <WsSpacer></WsSpacer>
      <WsState
        @search="$_onSearch"
        placeholder="搜尋"
        class="mt-8 mr-8"
        :style="[
          {
            width: '300px',
          },
        ]"
        type="search"
      />
      <WsBtn
        class="ma-8"
        @click="$_onCreateFolder()"
      >新增資料夾</WsBtn>
      <WsBtn
        class="ma-8"
        @click="$_onUploadClick()"
      >新增檔案</WsBtn>
    </WsFlex>
    <WsActionApi
      fetchUrl="/drive/folder"
      :params="_params"
      @fetch="$_onFetchFolder($event)"
    >
    </WsActionApi>
    <WsInfiniteScroll
      class="mt-8"
      ref="WsInfiniteScroll"
      v-slot="{ items }"
      fetchUrl="/drive/file"
      :params="_params"
      :bodyScroll="true"
    >
      <table class="w-full">
        <tr>
          <td class="pa-8">
            <WsDes>名稱</WsDes>
          </td>
          <td class="pa-8">
            <WsDes>檔案大小</WsDes>
          </td>
          <td class="pa-8">
            <WsDes>最後更新時間</WsDes>
          </td>
        </tr>
        <FileSystemDriveListFolder
          v-for="(item) in _folderItems"
          :key="`folder_${item.id}`"
          :folder="item"
          :urlPrefix="urlPrefix"
          :switchMode="switchMode"
          @delete="$_onDeleteFolder(item)"
          @switch="$_onFolderSwitch($event)"
        />
        <component
          :is="_listItemComponent"
          v-for="(item) in items"
          :key="`file_${item.id}`"
          v-bind="item"
          @delete="$_onDeleteFile(item)"
        ></component>
      </table>
    </WsInfiniteScroll>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMounted: false,
      C_folder: null,
      layoutMode: "list",
      modeldatas: {
        folders: null,
      },
      extendFolders: [],
    };
  },
  methods: {
    $_onSearch($event) {
      if (!$event) {
        return;
      }
      this.$router.push("/drive/search?search=" + $event);
    },
    $_onFolderSwitch($event) {
      this.C_folder = $event.id;
    },
    $_onDeleteFolder(item) {
      const _modeldataIndex = this.modeldatas.folders.findIndex(
        (folder) => folder.id === item.id
      );

      if (_modeldataIndex > -1) {
        this.modeldatas.folders.splice(_modeldataIndex, 1);
        return;
      }

      const _extendFolderIndex = this.extendFolders.findIndex(
        (folder) => folder.id === item.id
      );

      if (_extendFolderIndex > -1) {
        this.extendFolders.splice(_extendFolderIndex, 1);
      }
    },
    $_onDeleteFile(item) {
      this.$refs.WsInfiniteScroll.removeItem(item.id);
    },
    async $_onSumitCreateFolder(state) {
      const res = await this.$axios.post("/drive/folder", {
        name: state.name,
        folder: this.C_folder,
      });
      this.extendFolders.push(res.data.data);
    },
    $_onCreateFolder() {
      this.$store.dispatch("app/setStateDialog", {
        fields: this._folderFields,
        data: {},
        title: "新增資料夾",
        onSubmit: this.$_onSumitCreateFolder,
      });
    },
    $_onFetchFolder($event) {
      this.modeldatas.folders = $event.data;
    },
    async $_onUploadChange($event) {
      const files = $event.target.files;
      const tasks = [];
      try {
        this.$store.dispatch("app/startPageLoading");
        for (let i = 0; i < files.length; i++) {
          tasks.push(
            this.$o_o.$s._m.file_system_file.upload(
              files[i],
              "drive",
              this.C_folder
            )
          );
        }
        const res = await Promise.all(tasks);
        this.$refs.WsInfiniteScroll.addItems(res);
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
  },
  computed: {
    _folderItems() {
      if (!this.modeldatas.folders) return [];
      return [...this.extendFolders, ...this.modeldatas.folders];
    },
    _folderFields() {
      return {
        name: this.$store.state.stone_model.folder.fields.name,
      };
    },
    _params() {
      return {
        folder: this.C_folder,
      };
    },
    _listItemComponent() {
      if (this.layoutMode === "list") {
        return "FileSystemDriveListItem";
      } else {
        return "FileSystemDriveGridItem";
      }
    },
  },
  mounted() {
    this.C_folder = this.folder;
    this.isMounted = true;
  },
  props: {
    switchMode: {
      type: String,
      default: "route",
    },
    folder: {
      type: [String, Number],
    },
    urlPrefix: String,
  },
  watch: {},
};
</script>

<style>
</style>