<template>
  <WsCard class="ws-model-simple-index">
    <WsText
      size="16"
      class="mb-8"
    >{{ label }}</WsText>
    <div
      v-for="customBtn in customBtns"
      :key="customBtn.label"
      class="mb-8"
    >
      <WsBtn
        @click="$_onClickCustomBtn(customBtn)"
        :to="customBtn.to"
      >{{ customBtn.label }}</WsBtn>
    </div>
    <WsModelIndexTabs
      v-if="filterTabs && filterTabs.length > 0"
      v-model="filter"
      :items="filterTabs"
      class="mb-8"
    />
    <WsModelFetchIndex
      ref="WsModelFetchIndex"
      :modelName="modelName"
      :params="_params"
      :fetchUrl="_fetchUrl"
      v-slot="{modeldatas, meta, loading}"
    >
      <WsModelIndexTable
        :modeldatas="modeldatas"
        v-bind="$props"
        :fields="_fields"
        :showFields="showFields"
        :readable="readable"
        :inRowBtnRead="true"
        :showIndexSelects="false"
        @read="$_onRead"
        @custom-table-action="$_onCustomTableAction($event)"
      />
      <WsModelIndexPaginate v-bind="meta" v-model="page">
        <template #top>
          <WsLoading v-if="loading" type="line"/>
        </template>
      </WsModelIndexPaginate>
    </WsModelFetchIndex>
    <WsReadDialog
      ref="WsReadDialog"
      :fields="_readFields"
      :modelName="modelName"
      :updatable="false"
      :deletable="false"
      :getReadFetchUrl="$_getReadFetchUrl"
      fetchOnOpen
    >
      <template v-slot:afterContent="{ item }">
        <slot
          name="WsReadDialogAfterContent"
          :item="item"
        />
      </template>
      <template v-slot:rightActions>
        <WsBtn @click="$refs.WsReadDialog.close()">關閉</WsBtn>
      </template>
    </WsReadDialog>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      filter: {},
      page: 1,
    };
  },
  computed: {
    _params() {
      return {
        ...this.params,
        ...this.filter,
        page: this.page,
      };
    },
    _fields() {
      if (this.fields) {
        return this.fields;
      } else {
        return this.$store.state.stone_model[this.modelName].fields;
      }
    },
    _fetchUrl() {
      if (this.fetchUrl) {
        return this.fetchUrl;
      } else {
        return `/my/${this.modelName}`;
      }
    },
    _readFields() {
      if (this.readDialogFields?.length > 0) {
        return {
          ...this.$o_o.$h.model.getFieldsByKeys(
            this.$store.state.stone_model[this.modelName].fields,
            this.readDialogFields
          ),
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    $_onClickCustomBtn(customBtn) {
      if (customBtn.onClick) {
        customBtn.onClick(this);
      }
    },
    $_getReadFetchUrl(item) {
      if (item) {
        return `/my/${this.modelName}/${item.id}`;
      }
    },
    $_onRead($event) {
      if (this.emitRead) {
        this.$emit("read", $event);
      } else {
        this.$refs.WsReadDialog.open($event);
      }
    },
    $_onCustomTableAction($event) {
      this.$emit($event.emit, $event.data);
      this.$emit("custom-table-action", $event);
    },
    $_fetchModelData() {
      this.$refs.WsModelFetchIndex.$_fetchModel();
    },
    getModeldataIds() {
      return this.$refs.WsModelFetchIndex.getModeldataIds();
    },
  },
  props: {
    modelName: String,
    label: String,
    params: Object,
    filterTabs: {
      type: Array,
      default() {
        return [];
      },
    },
    showFields: {
      type: Array,
      default() {
        return [];
      },
    },
    readDialogFields: {
      type: Array,
      default() {
        return [];
      },
    },
    customBtns: {
      type: Array,
      default() {
        return [];
      },
    },
    customTableActions: {
      type: Array,
      default() {
        return [];
      },
    },
    fetchUrl: {},
    readable: {
      type: Boolean,
      default: true,
    },
    emitRead: {},
    dialogRead: {},
    fields: {},
  },
};
</script>

<style lang="scss">
.ws-model-simple-index {
  .ws-model-index__tabs {
    background-color: transparent !important;
    .ws-model-index__tabs__container {
      padding: 0 !important;
    }
  }
}
</style>