export default {
  getCreateBatchFields(data, res) {
    let _res
    if (res) {
      _res = res
    } else {
      _res = {}
    }
    const stone_model = data.stone_model
    _res = {
      ..._res,
      testing_cases: stone_model.xc_task.fields.testing_cases,
    }
    return _res
  }
}