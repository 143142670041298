<template>
  <WsDialog
    ref="WsDialog"
    v-model="dialog"
    :title="`${$t('new')} ${$t(label)}`"
    @close="$_onDialogClose()"
  >
    <template v-slot:content>
      <div>
        <slot name="contentTop"></slot>
        <WsErrorMessage
          v-if="pageErrorMessage"
          class="mb-20"
        >{{ pageErrorMessage }}</WsErrorMessage>
        <ValidationObserver ref="form">
          <form
            ref="createForm"
            @submit.prevent
          >
            <WsStateForm
              v-model="state"
              :fields="_fields"
              :modelName="modelName"
              :errorMessages="errorMessages"
            ></WsStateForm>
          </form>
        </ValidationObserver>
      </div>
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onCancel()"
        color="textSecondary"
        outlined
      >{{ $t('cancel') }}</WsBtn>
      <WsBtn
        @click="$_onSubmit()"
        :loading="submitLoading"
        color="primary"
      >{{ $t('submit') }}</WsBtn>
    </template>
  </WsDialog>
</template>

<script>
export default {
  name: "WsCreateDialog",
  data: () => ({
    pageErrorMessage: null,
    state: {},
    submitLoading: false,
    dialog: false,
    modelData: null,
  }),
  methods: {
    $_onDialogClose() {
      this.close();
    },
    $_keydownHandler(event) {
      if (event.metaKey && event.key == "Enter") {
        this.$_onSubmit();
      }
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      const postData = this.$o_o.$h.model.getFormatedStates(
        this._fields,
        this.state,
        this.postEncode
      );
      this.$emit("submit", {
        ...postData,
      });
    },
    $_removeFieldFromArray(array, fieldName) {
      if (!array) {
        return [];
      }
      const _array = [];
      array.forEach((arrayItem) => {
        const _item = {};
        for (let key in arrayItem) {
          if (key == fieldName) {
            continue;
          } else {
            _item[key] = arrayItem[key];
          }
        }
        _array.push(_item);
      });
      return _array;
    },
    $_onCancel() {
      this.close();
    },
    close() {
      this.dialog = false;
      this.pageErrorMessage = null;
      this.reset();
      window.removeEventListener("keydown", this.$_keydownHandler);
    },
    reset() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.state = {};
      this.submitLoading = false;
    },
    open(item = null) {
      this.modelData = item;
      this.dialog = true;
      this.$o_o.$h.state.defaultValueSet(this.state, this._fields);
      window.addEventListener("keydown", this.$_keydownHandler);
    },
  },
  computed: {
    _fields() {
      if (this.getFields) {
        return this.getFields(this.state, this.fields);
      } else {
        return this.fields;
      }
    },
  },
  props: {
    postEncode: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: Object,
      default: null,
    },
    modelName: String,
    label: {
      type: String,
      default: null,
    },
    fields: Object,
    getFields: {},
  },
};
</script>