<template>
  <div>
    <WsActionApi
      :fetchUrl="`xc_project/${xc_project.id}/member_month_days_format`"
      @fetch="$_onFetch($event)"
    >
      <XcProjectMemberMonthDayReadTable
        v-if="member_month_days_format"
        ref="XcProjectMemberMonthDayReadTable"
        :items="[]"
        :start_at="xc_project.start_at"
        :end_at="xc_project.end_at"
        :exportable="true"
        :importable="true"
        :clearable="true"
      />
    </WsActionApi>
    <ValidationObserver
      ref="form"
      class="ws-content-section-observer"
    >
      <form
        ref="updateForm"
        @submit.prevent
      >
        <WsStateForm
          class="mt-20"
          :fields="$store.state.stone_model.xc_project_member_month_day.fields"
          v-model="state"
        ></WsStateForm>
      </form>
    </ValidationObserver>
    <WsBtn
      class="mt-20"
      @click="$_onSubmit()"
    >{{$t('送出')}}</WsBtn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {},
      member_month_days_format: null,
    };
  },
  props: {
    xc_project: {},
  },
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$store.dispatch("app/startPageLoading");
      try {
        const _state = this.$refs.XcProjectMemberMonthDayReadTable.getState();
        const _postdata = {
          ...this.state,
          member_month_days: _state,
          xc_project: this.xc_project.id,
        };
        await this.$axios.post(`xc_project_member_month_day`, _postdata);
        this.$store.dispatch("app/addSnack", this.$t("送出完成"));
        this.$router.push(`/xc_project/${this.xc_project.id}/member_month_day`);
      } catch (error) {
        const match = this.$o_o.$h.http.checkErrorMatchExp(
          error,
          /xc_employee (\S+) not found/
        );
        if (match) {
          const employeeNo = match[1];
          alert(
            this.$t("xc_employee {no} not found", {
              no: employeeNo,
            })
          );
        } else {
          alert(this.$t(this.$o_o.$h.http.getErrorMessageFromRes(error)));
        }
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onFetch($event) {
      this.member_month_days_format = $event.member_month_days;
    },
  },
  computed: {},
};
</script>

<style>
</style>