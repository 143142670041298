<template>
  <div>
    <WsExcel
      ref="WsExcel"
      v-if="states"
      :fields="_fields"
      :showFields="_showFields"
      v-model="states"
      @input="$_onInput"
      :readonly="readonly"
      :exportable="exportable"
      :exportFilename="exportFilename"
      :importable="importable"
      :clearable="clearable"
    ></WsExcel>
  </div>
</template>

<script>
export default {
  computed: {
    _years() {
      const _years = [];
      const start_at = this.start_at;
      const end_at = this.end_at;
      const _startYear = start_at.substring(0, 4);
      const _endYear = end_at.substring(0, 4);
      let _year = _startYear;
      while (_year <= _endYear) {
        _years.push(`${_year}`);
        _year++;
      }
      return _years;
    },
    _yearQuarters() {
      const _yearQuarters = [];
      for (const year_month of this._yearMonths) {
        const year = year_month.substring(0, 4);
        const month = year_month.substring(4, 6);
        const quarter = Math.ceil(month / 3);
        const yearQuarter = `${year}Q${quarter}`;
        if (!_yearQuarters.includes(yearQuarter)) {
          _yearQuarters.push(yearQuarter);
        }
      }
      return _yearQuarters;
    },
    _yearMonths() {
      const _yearMonths = [];

      // get year months from start_at to end_at
      const start_at = this.$moment(this.start_at);
      const end_at = this.$moment(this.end_at);
      const _startYear = start_at.year();
      const _startMonth = start_at.month() + 1;
      const _endYear = end_at.year();
      const _endMonth = end_at.month() + 1;
      let _year = _startYear;
      let _month = _startMonth;

      while (
        (_year <= _endYear && _month != _endMonth) ||
        _month == _endMonth
      ) {
        const _yearMonth = this.$moment()
          .year(_year)
          .month(_month - 1)
          .format("YYYYMM");
        _yearMonths.push(_yearMonth);
        if (_month == 12) {
          _year++;
          _month = 1;
        } else {
          _month++;
        }
      }

      return _yearMonths;
    },
    _showFields() {
      const _showFields = [
        "role_name",
        "xc_employee_department_name",
        "name",
        "no",
        "start_at",
        "end_at",
      ];
      for (const _year_month of this._yearMonths) {
        _showFields.push(_year_month);
      }
      for (const _year_quater of this._yearQuarters) {
        _showFields.push(_year_quater);
      }
      const _years = this._years;
      for (const _year of _years) {
        _showFields.push(_year);
      }
      return _showFields;
    },
    _fields() {
      const _fields = {
        role_name: {
          label: "角色",
          // editable: false,
        },
        xc_employee_department_name: {
          label: "部門",
          width: 60,
          // editable: false,
        },
        name: {
          label: "姓名",
          // editable: false,
        },
        no: {
          label: "員工編號",
          // editable: false,
        },
        month_member_amount: {
          label: "規劃人月",
          // editable: false,
        },
        start_at: {
          label: "開始時間",
          // editable: false,
          width: 100,
        },
        end_at: {
          label: "結束時間",
          // editable: false,
          width: 100,
        },
      };

      const _yearMonths = this._yearMonths;
      for (const year_month of _yearMonths) {
        _fields[year_month] = {
          label: year_month,
          type: "number",
          width: 60,
        };
        // const year = year_month.substring(0, 4);
        // const month = year_month.substring(4, 6);
        // const now = this.$moment();
        // const _year = now.year();
        // const _month = now.format("MM");
        // if (year < _year || (year == _year && month < _month)) {
        //   _fields[year_month].editable = false;
        // }
      }

      const _year_quarters = this._yearQuarters;
      for (const _year_quater of _year_quarters) {
        _fields[_year_quater] = {
          label: `${_year_quater}`,
          type: "number",
          // editable: false,
          width: 90,
        };
      }

      const _years = this._years;
      for (const _year of _years) {
        _fields[`${_year}`] = {
          label: `${_year}`,
          type: "string",
          // editable: false,
          textAlign: "right",
          width: 90,
        };
      }
      return _fields;
    },
  },
  data() {
    return {
      timeouts: {},
      states: null,
    };
  },
  methods: {
    exportExcel() {
      this.$refs.WsExcel.exportExcel();
    },
    $_setQuartersAndYearsOfState(state) {
      if (!state) {
        return state;
      }
      const _year_quarters = this._yearQuarters;
      const _years = this._years;
      for (const stateItem of state) {
        for (const _year_quarter of _year_quarters) {
          let year_quater_value = 0;
          for (const year_month in stateItem) {
            const year = year_month.substring(0, 4);
            const month = year_month.substring(4, 6);
            const quarter = Math.ceil(month / 3);
            const yearQuarter = `${year}Q${quarter}`;
            if (yearQuarter === _year_quarter) {
              if (stateItem[year_month] != null) {
                year_quater_value =
                  parseFloat(
                    year_quater_value * 100 + stateItem[year_month] * 100
                  ) / 100;
              }
            }
          }
          stateItem[_year_quarter] = year_quater_value;
        }
        for (const _year of _years) {
          let year_value = 0;
          for (const year_month of this._yearMonths) {
            const year = year_month.substring(0, 4);
            if (year === _year) {
              if (stateItem[year_month] != null) {
                year_value =
                  parseFloat(year_value * 100 + stateItem[year_month] * 100) /
                  100;
              }
            }
          }
          stateItem[_year] = year_value;
        }
      }
      return state;
    },
    $_onInput($event) {
      const _value = this.$_setQuartersAndYearsOfState($event);
      this.states = _value;
    },
    getState() {
      return this.states;
    },
    $_setState() {
      let _items = [];
      if (!this.items) {
        return _items;
      }
      for (const item of this.items) {
        const _item = {
          id: item.xc_project_member_id,
          $id: item.xc_project_member_id,
          xc_project_member_id: item.xc_project_member_id,
          role_name: item.role_name,
          no: item.no,
          start_at: this.$moment(item.start_at).format("YYYY-MM-DD"),
          end_at: this.$moment(item.end_at).format("YYYY-MM-DD"),
          xc_employee_department_name: item.xc_employee_department_name,
          name: item.name,
        };
        for (const year_month of this._yearMonths) {
          _item[year_month] = item.member_month_days_of_member[year_month];
        }
        _items.push(_item);
      }
      _items = this.$_setQuartersAndYearsOfState(_items);
      this.states = [..._items];
    },
  },
  mounted() {
    this.$_setState();
  },
  props: {
    exportFilename: {
      default: "計畫人月",
    },
    items: {},
    start_at: {},
    end_at: {},
    exportable: {},
    importable: {},
    clearable: {},
    readonly: {},
  },
  watch: {},
};
</script>

<style>
</style>