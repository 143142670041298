export default {
  getXcProjectMenu(data, res) {
    res.splice(1, 0, {
      title: "Test Case",
      link: `/xc_project/${data.id}/testing_case`,
    });
    return res
  },
  getXcProjectExtendRoutes(data, res) {
    let _routes = []
    if (res) {
      _routes = res
    }
    _routes.push(...[
      {
        path: 'testing_case',
        name: 'testing_case',
        component: () => import('@/__modules/testing/extend_modules/xc_project/views/XcProject/TestCase/Index.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'testing_case/create',
        name: 'testing_case_create',
        component: () => import('@/__modules/testing/extend_modules/xc_project/views/XcProject/TestCase/Create.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'testing_case/create/batch',
        name: 'testing_case_create_batch',
        component: () => import('@/__modules/testing/extend_modules/xc_project/views/XcProject/TestCase/CreateBatch.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'testing_case/:testing_case_id',
        name: 'testing_case_read',
        component: () => import('@/__modules/testing/extend_modules/xc_project/views/XcProject/TestCase/Read.vue'),
        meta: {
          middleware: 'auth',
        },
      },
      {
        path: 'testing_case/:testing_case_id/update',
        name: 'testing_case_update',
        component: () => import('@/__modules/testing/extend_modules/xc_project/views/XcProject/TestCase/Update.vue'),
        meta: {
          middleware: 'auth',
        },
      },
    ])
    return _routes
  }
}