<template>
  <div>
    <WsMain>
      <ValidationObserver
        ref="form"
        class="ws-content-section-observer"
      >
        <form
          ref="updateForm"
          @submit.prevent
        >
          <WsStateForm
            v-model="state"
            :fields="fields"
          />
        </form>
      </ValidationObserver>
      <WsBtn
        class="mt-40"
        @click="$_onSubmit()"
      >{{ $t('submit') }}</WsBtn>
    </WsMain>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {},
      fields: {
        year: {
          type: "year",
          label: "年份",
          rules: "required",
          minYear: 2010,
          maxYear: this.$moment().year(),
        },
        month: {
          type: "month",
          label: "月份",
          rules: "required",
        },
      },
    };
  },
  methods: {
    async $_onSubmit() {
      console.log("_to", this._to);
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$router.push(this._to);
    },
  },
  computed: {
    _to() {
      return `/print/my/xc_employee_salary_slip?year=${this.state.year}&month=${this.state.month}`;
    },
  },
};
</script>

<style>
</style>