<template>
  <WsMain>
    <WsReadSection
      ref="WsReadSection"
      :label="$store.state.stone_model.xc_employee_salary_payjob.label"
      :fields="$store.state.stone_model.xc_employee_salary_payjob.fields"
      v-bind="$store.state.stone_model.xc_employee_salary_payjob"
      :leftFields="_leftFields"
      v-model="state"
    />
    <WsActionApi
      class="mt-40"
      v-if="state.id"
      fetchUrl="/xc_employee_salary_slip"
      :params="_childCrudParams"
      v-slot="{ res, isFetching }"
    >
      <WsLoading v-if="isFetching"></WsLoading>
      <XcEmployeeSalarySlipReadTable
        v-else
        :xc_employee_salary_slips="$_getSlips(res.data)"
      />
    </WsActionApi>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      state: {},
    };
  },
  computed: {
    _childCrudParams() {
      return {
        xc_employee_salary_payjob: this.state.id,
        getall: true,
      };
    },
    _leftFields() {
      return ["review_xc_employee", "start_at", "end_at", "xc_employees"];
    },
  },
  methods: {
    $_getSlips(slips) {
      const _slips = [...slips];
      _slips.sort((a, b) => {
        if (a.hire_change_type && !b.hire_change_type) {
          return -1;
        }
      });
      return _slips;
    },
  },
};
</script>

<style>
</style>