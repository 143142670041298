<template>
  <div class="ws-state__label-wrap">
    <div class="ws-state__label-leftwrap">
      <WsText
        v-if="label"
        class="ws-state__label"
        :size="labelSize"
      >{{ label }} {{ des }}<span
          v-if="$_hasRule('required')"
          :style="{ color: $color.danger }"
        >*</span>
      </WsText>
      <WsTooltip
        v-if="tooltip"
        :value="$t(tooltip)"
      >
        <WsIcon
          class="ws-state__tooltip"
          name="icon-ws-outline-help-outline"
        />
      </WsTooltip>
    </div>
    <WsText
      v-if="maxlength"
      :size="labelSize"
      class="ws-state__word_count"
    >
      {{ _word_count }}
    </WsText>
  </div>
</template>

<script>
export default {
  methods: {
    $_hasRule(rule) {
      if (!this.rules) {
        return false;
      }
      const _rules = this.rules.split("|");
      const exist = _rules.find((e) => {
        return e == rule;
      });
      return exist;
    },
  },
  props: {
    label: {},
    labelSize: {
      type: [String, Number],
      default: 14,
    },
    des: {},
    tooltip: {},
    value: {},
    maxlength: {},
    rules: {},
  },
  computed: {
    _word_count() {
      let _value = this.value ? this.value.length : 0;
      return `${_value}/${this.maxlength}`;
    },
  },
};
</script>

<style>
</style>