<template>
  <WsDialog
    ref="WsDialog"
    v-model="dialog"
    :title="title"
    :type="type"
  >
    <template v-slot:content>
      <SlickList
        class="ws-sort-item-list"
        v-if="C_value"
        v-model="C_value"
      >
        <SlickItem
          class="ws-sort-item"
          v-for="(stage,stageIndex) in C_value"
          :key="stageIndex"
          :index="stageIndex"
        >
          <WsInfoForm
            v-if="Array.isArray(_textKey)"
            :fields="_textKeyFields"
            :value="stage"
            :displayLabel="false"
          />
          <WsInfo
            v-else
            :infoType="_textKeyType"
            :value="stage[_textKey]"
          />
          <!-- <WsImg
            v-else-if="_textKey == 'real_image'"
            :url="stage.image"
            class="contain-object"
            previewMaxWidth="150px"
            previewMaxHeight="unset"
          />
          <WsImg
            v-else-if="textKeyType == 'image'"
            :url="stage[_textKey] || stage"
            class="contain-object"
            previewMaxWidth="150px"
            previewMaxHeight="unset"
          />
          <WsText v-else-if="_textKey == 'name' || _textKey == 'text'">11{{stage.name||stage.text||$_stageContent(stage.content)}}</WsText>
          <div v-else>
            <WsImg
              :url="stage.cover_image"
              class="contain-object"
              previewMaxWidth="150px"
              previewMaxHeight="unset"
            />{{ stage[_textKey] }}
          </div> -->
        </SlickItem>
      </SlickList>
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onCancel()"
        :minWidth="100"
        color="textSecondary"
        outlined
      >{{$t('cancel')}}</WsBtn>
      <WsBtn
        @click="$_onSubmit()"
        :minWidth="100"
        color="primary"
      >{{$t('submit')}}</WsBtn>
    </template>
  </WsDialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    C_value: null,
  }),

  methods: {
    close() {
      this.dialog = false;
      window.removeEventListener("keydown", this.$_keydownHandler);
    },
    $_onCancel() {
      this.close();
    },
    async $_onSubmit() {
      this.$emit("input", this.C_value);
      this.close();
    },
    $_keydownHandler(event) {
      if (event.metaKey && event.key == "Enter") {
        this.$_onSubmit();
      }
    },
    open() {
      this.$_valueInit();
      this.dialog = true;
      window.addEventListener("keydown", this.$_keydownHandler);
    },
    $_valueInit() {
      this.C_value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
    },
    $_stageContent(data) {
      if (typeof data !== "string") {
        return data;
      }
      if (data.length > 10) {
        return data.slice(0, 10) + "...";
      } else {
        return data;
      }
    },
  },
  computed: {
    _textKey() {
      if (this.textKey?.length == 0) {
        return "title";
      } else {
        return this.textKey;
      }
    },
    _textKeyType() {
      if (this.textKey && this.textKeyFields) {
        let _keyType = this.textKeyFields[this.textKey]?.type;
        return _keyType;
      } else return undefined;
    },
    _textKeyFields() {
      let _textKeyFields = {};
      this.textKey.forEach((key) => {
        if (this.textKeyFields[key]) {
          _textKeyFields[key] = this.textKeyFields[key];
          _textKeyFields[key].sm = 2;
        }
      });
      return _textKeyFields;
    },
  },
  props: {
    type: {
      type: String,
      default: "panel-right",
    },
    title: {
      type: String,
      default() {
        return this.$t("adjust_the_order");
      },
    },
    value: {
      type: Array,
      default: null,
    },
    textKey: {
      type: [String, Array],
      default: "title",
    },
    textKeyType: {
      type: String,
      default: "",
    },
    textKeyFields: Object,
  },
};
</script>

<style>
</style>