import config from '@/__config';

const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: "xc_service_order",
  label: "訂單",
  fields: {
    name: {
      type: 'text',
      label: '名稱',
      autofocus: true
    },
    tax_type: {
      type: "select",
      label: "應稅",
      defaultValue: 1,
      items: [
        {
          value: 1,
          text: '應稅'
        },
        {
          value: 2,
          text: '未稅'
        },
      ]
    },
    price: {
      type: 'currency',
      label: '金額'
    },
    deal_date: {
      type: 'date',
      label: "成交時間",
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    relate_files: {
      type: 'list',
      label: "相關檔案",
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        file: {
          type: 'file',
          label: '檔案'
        }
      }
    },
    relate_links: {
      type: 'list',
      label: "相關連結",
      fields: {
        name: {
          type: 'text',
          label: '名稱'
        },
        link: {
          type: 'link',
          label: '連結'
        }
      }
    },
    owner: {
      type: "belongsTo",
      label: "歸屬人",
      textKey: "name",
      modelName: config.wsmodule.admin.admin_blur ? 'cmser' : 'admin',
    },
  },
  crudSetting: {
    index: {
      showFields: [
        "name",
        "price",
        "deal_date",
        "owner",
      ],
      pageMode: true,
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "name",
                "owner",
                "deal_date",
                "tax_type",
                "price",
                "remark",
                "relate_files",
                "relate_links",
              ],
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                "name",
                "owner",
                "deal_date",
                "tax_type",
                "price",
                "remark",
                "relate_files",
                "relate_links",
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        "name",
        "owner",
        "deal_date",
        "tax_type",
        "price",
        "remark",
        "relate_files",
        "relate_links",
      ],
      rightFields: [
      ],
      titleKey: 'name',
      childCruds: [
        {
          modelName: 'xc_service_order_stage',
          // orderLayerFields: [],
          pageMode: true,
          inRowBtnRead: true,
          dialogCreate: true,
          // dialogRead: true,
          dialogUpdate: true,
          showFields: [
            'stage_name',
            "price",
            "price_after_tax",
            "invoice_date",
            "invoice_number",
            "transfer_date",
          ]
        }
      ]
    }
  }
}

export default model