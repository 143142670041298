<template>
  <div
    class="ws-menu"
    :class="{active:active}"
  >
    <div
      @click="$_onTitleClick()"
      class="ws-menu__title"
      v-ripple.300
    >
      <WsIcon
        class="pre-icon"
        v-if="icon"
        :name="icon"
      />
      <WsText
        size="14"
        class="xs-size-24"
      >{{$t(title)}}</WsText>
      <WsIcon
        size="14"
        class="arrow"
        name="icon-md-arrow-drop-down"
      />
    </div>
    <slide-x-left-transition :duration="300">
      <div
        class="ws-menu__menu"
        v-if="active"
      >
        <WsMenuItem
          class="ml-48"
          v-for="(menuItem,menuIndex) in menu"
          :key="menuIndex"
          :title="menuItem.title"
          :link="menuItem.link"
        ></WsMenuItem>
      </div>
    </slide-x-left-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active:
        this.$config.cms &&
        this.$config.cms.navigationDrawer &&
        this.$config.cms.navigationDrawer.menuDefaultActive
          ? this.$config.cms &&
            this.$config.cms.navigationDrawer &&
            this.$config.cms.navigationDrawer.menuDefaultActive
          : false,
    };
  },
  methods: {
    $_onTitleClick() {
      this.active = !this.active;
    },
  },
  props: {
    menu: {
      type: [Array, Object],
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    // titleInLocale: {
    //   type: Boolean,
    //   default: false,
    // },
  },
};
</script>