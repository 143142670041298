<template>
  <div>
    <LayoutModelBatchCreate
      :oneForAllFields="_onForAllFields"
      :createShowFields="createShowFields"
      modelName="testing_case"
      :extendParams="_extendParams"
    ></LayoutModelBatchCreate>
  </div>
</template>

<script>
export default {
  data() {
    return {
      createShowFields: [
        "testing_case_template",
        "name",
        "des",
        "condition",
        "priority",
        "testing_case_items",
        "create_template",
      ],
    };
  },
  props: {
    xc_project: {},
  },
  computed: {
    _extendParams() {
      return {
        xc_project: this.xc_project.id,
      };
    },
    _onForAllFields() {
      return {
        xc_milestones: {
          ...this.$store.state.stone_model.testing_case.fields.xc_milestones,
          getExtendParamsFromValue: () => {
            return {
              xc_project: this.xc_project.id,
            };
          },
        },
      };
    },
  },
};
</script>

<style>
</style>