<template>
  <WsFlex
    class="ws-model-index__title-bar d-xs-none px-20 py-12"
    justifyContent="space-between"
  >
    <WsText
      size="20"
      class="mt-6"
    >{{$t(label)}}</WsText>
    <WsFlex>
      <WsBtn
        v-for="(customBtn, customBtnIndex) in customBtns"
        :key="`btn${customBtnIndex}`"
        class="mr-20"
        :to="customBtn.to"
        :disabled="customBtn.disabled"
        @click="$_onCustomBtnClick(customBtn)"
      >
        <WsIcon
          v-if="customBtn.icon&&customBtn.icon.left"
          :size="customBtn.icon.size||'24'"
          :name="customBtn.icon.name||'icon-md-menu'"
          class="mr-3"
        />
        {{customBtn.label}}
        <WsIcon
          v-if="customBtn.icon&&customBtn.icon.right"
          :size="customBtn.icon.size||'24'"
          :name="customBtn.icon.name||'icon-md-menu'"
          class="mr-3"
        />
      </WsBtn>
      <WsBtn
        v-if="exportable"
        class="mr-20"
        @click="$emit('export')"
      >
        <WsIcon name="icon-md-file-download" />
        <div class="
          ml-6">{{$t("匯出")}}</div>
      </WsBtn>
      <WsBtn
        v-if="importformatdownloadable"
        class="mr-20"
        @click="$emit('importformatdownload')"
      >
        <WsIcon name="icon-md-file-download" />
        <div class="
          ml-6">{{$t("匯入格式下載")}}</div>
      </WsBtn>
      <WsBtn
        class="mr-20"
        v-if="importable"
        outlined
        @click="$emit('import')"
      >
        <WsIcon name="icon-md-publish" />
        <div class="
          ml-6">{{$t('匯入')}}</div>
      </WsBtn>
      <WsBtn
        class="mr-20"
        v-if="orderable"
        :to="_orderUrl"
        :outlined="true"
      >
        <WsIcon name="icon-md-reorder" />
        <div class="ml-6">{{$t('排序')}}</div>
      </WsBtn>
      <WsBtn
        class="mr-20"
        v-if="batchcreatable"
        :to="`/${_urlModelName}/sheetcreate`"
      >
        <WsIcon name="icon-ws-outline-add" />
        <div class="
          ml-6">批次新增</div>
      </WsBtn>
      <WsBtn
        v-if="creatable&&(pageMode&&!dialogCreate)"
        :to="_createUrl"
      >
        <WsIcon name="icon-ws-outline-add" />
        <div class="ml-6">{{$t('new')}}</div>
      </WsBtn>
      <WsBtn
        v-if="creatable&&(!pageMode||dialogCreate)"
        @click="$emit('create')"
      >
        <WsIcon name="icon-ws-outline-add" />
        <div class="ml-6">{{$t('new')}}</div>
      </WsBtn>
    </WsFlex>
  </WsFlex>
</template>

<script>
export default {
  props: {
    modelName: {},
    urlModelName: {},
    creatable: {
      default: true,
    },
    createUrl: {},
    dialogCreate: {},
    label: {},
    pageMode: {},
    orderable: {},
    importable: {},
    params: {},
    customBtns: {},
    exportable: {},
    batchcreatable: {},
    importformatdownloadable: {},
  },
  methods: {
    $_onCustomBtnClick(btn) {
      if (btn.to) {
        return;
      } else if (btn.emit) {
        this.$emit("customBtnClick", btn.emit);
      } else {
        this.$emit("customBtnClick", btn.event);
      }
    },
    // async $_onExport() {
    //   const res = await this.$axios.get(
    //     `${this.modelName}/export/excel/signedurl`,
    //     {
    //       params: this.params,
    //     }
    //   );
    //   window.open(res.data, "_blank");
    // },
  },
  computed: {
    // _customBtns() {
    //   const _customBtns = [];
    //   if (!this.customBtns) {
    //     return [];
    //   }
    //   this.customBtns.forEach((customBtn) => {
    //     if (customBtn.requireScopes) {
    //       if (!this.$o_o.$h.auth.hasScope(customBtn.requireScopes)) {
    //         return;
    //       }
    //     }
    //     _customBtns.push(customBtn);
    //   });
    //   return _customBtns;
    // },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
    _createUrl() {
      const _createUrl = this.createUrl
        ? this.createUrl
        : `/${this._urlModelName}/create`;
      return _createUrl;
    },
  },
};
</script>

<style lang="scss">
.ws-model-index__title-bar {
}
</style>