export default {
  getTaskFilterSelectsInXcProject(data, res) {
    let _res = res
    const xc_project = data.xc_project
    _res.xc_milestone = {
      type: 'belongsTo',
      label: 'Milestone',
      modelName: 'xc_milestone',
      extendParams: {
        xc_project: xc_project.id
      }
    }
    return _res
  }
}