import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'xc_employee_department',
    middleware: 'auth',
    meta: {},
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'xc_employee_type',
    middleware: 'auth',
    meta: {},
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'xc_employee_position_type',
    middleware: 'auth',
    meta: {},
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'xc_employee_working_schedule_setting',
    middleware: 'auth',
    meta: {},
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index', 'create', 'read', 'update'],
    modelName: 'xc_employee',
    middleware: 'auth',
    meta: {},
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'xc_employee_salary_update_record',
    middleware: 'auth',
    meta: {},
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index'],
    modelName: 'xc_employee_salary_transfer_account',
    middleware: 'auth',
    meta: {},
  }),
  ...H_Router.getCrudTemplateRoutes({
    routes: ['index', 'sheetcreate'],
    modelName: 'xc_employee_performance_bonus',
    middleware: 'auth',
    meta: {},
  }),
  // ...H_Router.getCrudTemplateRoutes({
  //   routes: ['index'],
  //   modelName: 'xc_employee_hire',
  //   middleware: 'auth',
  //   meta: {},
  // }),
  ...H_Router.getResourceRoutes({
    routes: ['index', 'create', 'update', 'read'],
    modelName: 'xc_employee_hire_new',
    viewsPath: '__modules/xc_employee/views',
  }),
  ...H_Router.getResourceRoutes({
    routes: ['index', 'create', 'update', 'read'],
    modelName: 'xc_employee_hire_change',
    viewsPath: '__modules/xc_employee/views',
  }),
  ...H_Router.getResourceRoutes({
    routes: ['index', 'read'],
    modelName: 'xc_employee_hire',
    viewsPath: '__modules/xc_employee/views',
  }),
  // ...H_Router.getResourceRoutes({
  //   routes: ['index'],
  //   modelName: 'xc_employee_salary_slip',
  //   viewsPath: '__modules/xc_employee/views',
  // }),
  // ...H_Router.getResourceRoutes({
  //   routes: ['index', 'create', 'update', 'read'],
  //   modelName: 'xc_employee_hire_renew',
  //   viewsPath: '__modules/xc_employee/views',
  // }),
  ...H_Router.getResourceRoutes({
    routes: ['index', 'create', 'update', 'read'],
    modelName: 'xc_employee_salary_adjustjob',
    viewsPath: '__modules/xc_employee/views',
  }),
  ...H_Router.getResourceRoutes({
    routes: ['index', 'create', 'update', 'read'],
    modelName: 'xc_employee_salary_payjob',
    viewsPath: '__modules/xc_employee/views',
  }),
  ...H_Router.getResourceRoutes({
    routes: ['index'],
    modelName: 'xc_employee_position',
    viewsPath: '__modules/xc_employee/views',
    meta: {
      middleware: 'auth',
      requiredScopes: ['xc_employee-admin'],
    },
  }),
  {
    path: '/xc_employee_salary_adjustjob/:id/adjustments/update',
    name: 'XcEmployeeSalaryUpdate',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeSalaryAdjustjob/AdjustmentUpdate.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee-admin'
      ]
    }
  },
  {
    path: '/xc_employee_salary_adjustjob/:id/adjustments/review',
    name: 'XcEmployeeSalaryAdjustJobReview',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeSalaryAdjustjob/AdjustmentReview.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee-admin'
      ]
    }
  },
  {
    path: '/xc_employee_salary_adjustjob_my_review',
    name: 'XcEmployeeSalaryAdjustJobMyReview',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeSalaryAdjustjob/IndexMyReview.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee-admin'
      ]
    }
  },
  {
    path: '/xc_employee_salary',
    name: 'XcEmployeeSalaryIndex',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeSalary/Index.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee-admin'
      ]
    }
  },
  {
    path: '/xc_employee_salary/:id',
    name: 'XcEmployeeSalaryShow',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeSalary/Show.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee-admin'
      ]
    }
  },
  {
    path: '/xc_employee_salary_payjob/:id/slips/update',
    name: 'XcEmployeeSalarySlipUpdate',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeSalaryPayjob/SlipUpdate.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee-admin'
      ]
    }
  },
  {
    path: '/xc_employee_salary_payjob/:id/slips/review',
    name: 'XcEmployeeSalarySlipReview',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeSalaryPayjob/SlipReview.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee-admin'
      ]
    }
  },
  {
    path: '/xc_employee_salary_payjob_my_review',
    name: 'XcEmployeeSalaryPayjobMyReview',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeSalaryPayjob/IndexMyReview.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee-admin'
      ]
    }
  },
  {
    path: '/xc_employee_hire_new_my_manage',
    name: 'XcEmployeeHireNewMyManage',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeHireNew/IndexMyManage.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee_hire-admin',
        'xc_employee-admin'
      ]
    }
  },
  {
    path: '/xc_employee_hire_change_my_manage',
    name: 'XcEmployeeHireChangeMyManage',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeHireChange/IndexMyManage.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee_hire-admin',
        'xc_employee-admin',
      ]
    }
  },
  {
    path: '/xc_employee_hire/:id/checkin',
    name: 'xc_employee_hire_checkin',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeHire/Checkin.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee_hire-admin',
        'xc_employee-admin',
      ]
    }
  },
  {
    path: '/xc_employee_hire/:id/apply',
    name: 'xc_employee_hire_apply',
    component: () => import('@/__modules/xc_employee/views/XcEmployeeHire/Apply.vue'),
    meta: {
      middleware: 'auth',
      requireScopes: [
        'xc_employee_hire-admin',
        'xc_employee-admin',
      ]
    }
  },
  {
    path: '/print/xc_employee_salary_slip',
    name: 'print_xc_employee_salary_slip',
    component: () =>
      import('@/__modules/xc_employee/views/XcEmployeeSalarySlip/Print.vue'),
    meta: {
      template: 'print',
      middleware: 'auth',
      requiredScopes: [
        'xc_employee_salary-admin',
        'xc_employee-admin',
      ],
    },
  },
  {
    path: '/print/my/xc_employee_salary_slip',
    name: 'print_my?xc_employee_salary_slip',
    component: () =>
      import('@/__modules/xc_employee/views/XcEmployeeSalarySlip/PrintMy.vue'),
    meta: {
      template: 'print',
      middleware: 'auth',
    },
  },
  {
    path: '/myemployee',
    name: 'myemployee',
    component: () =>
      import('@/__modules/xc_employee/views/XcEmployee/My.vue'),
    meta: {
      middleware: 'auth'
    },
  },
  {
    path: '/xc_employee/:id/setadmin',
    name: 'xc_employee_setadmin',
    component: () =>
      import('@/__modules/xc_employee/views/XcEmployee/SetAdmin.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: [
        'xc_employee-admin',
      ],
    },
  },
  {
    path: '/xc_employee/calc/cost',
    name: 'xc_employee_calc_cost',
    component: () =>
      import('@/__modules/xc_employee/views/XcEmployee/Costcalc.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: [
        'xc_employee-admin',
      ],
    },
  },
  {
    path: '/xc_employee_salary_slip',
    name: 'xc_employee_salary_slip',
    component: () =>
      import('@/__modules/xc_employee/views/XcEmployeeSalarySlip/Search.vue'),
    meta: {
      middleware: 'auth'
    },
  },
  {
    path: '/mysalary_slip',
    name: 'mysalary_slip',
    component: () =>
      import('@/__modules/xc_employee/views/XcEmployeeSalarySlip/SearchMy.vue'),
    meta: {
      middleware: 'auth'
    },
  },
];
export default routes