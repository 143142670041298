<template>
  <WsMain class="pb-80">
    <!-- <WsStateForm
      :fields="fields.general"
      v-model="state"
    >
    </WsStateForm> -->
    <WsState
      type="belongsTo"
      label="Task Template Group"
      modelName="xc_task_template_group"
      :value="state.xc_task_template_group"
      :extendParams="{'index_resource':'2' }"
      @input="$_onInputTaskTemplateGroup($event)"
    />
    <WsState
      v-bind="fields.general.xc_project"
      v-model="state.xc_project"
    />
    <WsState
      v-bind="fields.general.xc_milestone"
      v-model="state.xc_milestone"
      :extendParams="_xcMilestoneExtendParams"
    />
    <WsState
      v-bind="fields.general.relate_stored"
      v-model="state.relate_stored"
    />
    <WsCard
      class="mt-20"
      v-for="(xc_task,xc_taskIndex) in state.xc_tasks"
      :key="xc_taskIndex"
    >
      <WsFlex>
        <WsStateForm
          :value="xc_task"
          :fields="_fields"
          @input="$_onInputTask($event, xc_taskIndex)"
        ></WsStateForm>
        <WsIconBtn
          @click="$_onRemoveTask(xc_taskIndex)"
          name="icon-md-delete"
        ></WsIconBtn>
      </WsFlex>
    </WsCard>
    <WsBtn
      class="mt-20"
      @click="$_onTaskAdd()"
    >新增Task</WsBtn>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          class="ml-8"
          @click="$_onSubmit()"
        >{{ $t('submit') }}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        general: {
          xc_milestone:
            this.$store.state.stone_model.xc_task.fields.xc_milestone,
          xc_project: this.$store.state.stone_model.xc_task.fields.xc_project,
          relate_stored: {
            label: "關聯建立 Task",
            type: "switch",
          },
        },
      },
      state: {
        xc_task_template_group: null,
        xc_project: null,
        relate_stored: false,
        xc_tasks: [],
      },
    };
  },
  methods: {
    $_onInputTaskTemplateGroup($event) {
      this.state.xc_task_template_group = $event;
      if (this.state.xc_tasks.length) {
        return;
      }
      const _xc_tasks = [];
      this.state.xc_task_template_group.xc_task_templates.forEach(
        (xc_task_template) => {
          _xc_tasks.push({
            name: xc_task_template.name,
            content: xc_task_template.content,
            xc_task_execute_ref_items:
              xc_task_template.xc_task_execute_ref_items,
            tags: xc_task_template.tags,
          });
        }
      );
      this.state.xc_tasks = _xc_tasks;
    },
    async $_onSubmit() {
      const _xc_tasks = [];
      this.state.xc_tasks.forEach((xc_task) => {
        _xc_tasks.push({
          ...xc_task,
          xc_project: this.state.xc_project,
          xc_milestone: this.state.xc_milestone,
        });
      });
      const _state = {
        ...this.state,
        xc_tasks: _xc_tasks,
      };
      this.$store.dispatch("app/startPageLoading");
      try {
        await this.$axios.post("/xc_task/batch", _state);
        this.$router.push("/xc_task");
      } catch (error) {
        alert(this.$t(this.$o_o.$h.http.getErrorMessageFromRes(error)));
        console.error(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onRemoveTask(xc_taskIndex) {
      const _state = { ...this.state };
      _state.xc_tasks.splice(xc_taskIndex, 1);
      this.state = _state;
    },
    $_onInputTask($event, xc_taskIndex) {
      const _state = { ...this.state };
      _state.xc_tasks[xc_taskIndex] = $event;
      this.state = _state;
    },
    $_onTaskAdd() {
      this.state.xc_tasks.push({});
    },
  },
  computed: {
    _xcMilestoneExtendParams() {
      return {
        xc_project: this.state.xc_project?.id,
      };
    },
    _fieldsGeneral() {
      const _fieldsGeneral = {
        general: {
          // @Q@
          // xc_milestone:
          //   this.$store.state.stone_model.xc_task.fields.xc_milestone,
          // xc_project: this.$store.state.stone_model.xc_task.fields.xc_project,
          // relate_stored: {
          //   label: "關聯建立 Task",
          //   type: "switch",
          // },
        },
      };
      return _fieldsGeneral;
    },
    _fields() {
      let _fields = {
        name: this.$store.state.stone_model.xc_task.fields.name,
        taker: this.$store.state.stone_model.xc_task.fields.taker,
        due_date: this.$store.state.stone_model.xc_task.fields.due_date,
        xc_task_execute_ref_items:
          this.$store.state.stone_model.xc_task.fields
            .xc_task_execute_ref_items,
        tags: this.$store.state.stone_model.xc_task.fields.tags,
        content: this.$store.state.stone_model.xc_task.fields.content,
      };

      // _fields = this.$o_o.$h.module.extendModuleHelperAction(
      //   "xc_task",
      //   "xc_task.js",
      //   "getCreateBatchFields",
      //   {
      //     stone_model: this.$store.state.stone_model,
      //   },
      //   _fields
      // );
      return _fields;
    },
  },
};
</script>

<style>
</style>