<template>
  <div>
    <WsModelIndex
      ref="WsModelIndex"
      :label="$store.state.stone_model.xc_password_pair.label"
      :modelName="$store.state.stone_model.xc_password_pair.modelName"
      :fields="$store.state.stone_model.xc_password_pair.fields"
      :filterTabs="filterTabs"
      :showFields="$store.state.stone_model.xc_password_pair.crudSetting.index.showFields"
      :creatable="true"
      :updatable="true"
      :deletable="true"
      :readable="true"
      :dialogRead="true"
      :actionWidth="140"
      :pageMode="false"
      :createFields="_createFields"
      :updateFields="_updateFields"
      :paramsOnCrud="_paramsOnCrud"
    ></WsModelIndex>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `帳號密碼 | ${this.xc_project.name}`,
    };
  },
  computed: {
    _createFields() {
      const _createFields = {
        ...this.$store.state.stone_model.xc_password_pair.fields,
      };
      delete _createFields.xc_project;
      return _createFields;
    },
    _updateFields() {
      const _updateFields = {
        ...this.$store.state.stone_model.xc_password_pair.fields,
      };
      delete _updateFields.xc_project;
      return _updateFields;
    },
    _id() {
      return this.$route.params.id;
    },
    _paramsOnCrud() {
      return {
        xc_project: this._id,
      };
    },
  },
  data() {
    return {};
  },
  props: {
    xc_project: {},
  },
};
</script>

<style></style>
