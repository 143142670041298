<template>
  <WsCard class="off-day-function-board">
    <WsText
      size="16"
      class="mb-16"
    >常用功能</WsText>
    <WsFlex
      gap="16px"
      class="off-day-function-board-main-content"
    >
      <WsBtn
        v-for="(btn, index) in btns"
        :key="index"
        :to="btn.link"
      >
        <WsFlex
          flexDirection="column"
          gap="8px"
        >
          <WsIcon
            :name="btn.icon"
            size="30"
          />
          <WsText size="14">{{ btn.title }}</WsText>
        </WsFlex>
      </WsBtn>
    </WsFlex>
  </WsCard>
</template>

<script>
export default {
  data() {
    return {
      btns: [
        {
          icon: "icon-bih-account",
          title: "請假",
          link: "/my/leave_day",
        },
        {
          icon: "icon-md-work",
          title: "加班",
          link: "/my/xc_work_overtime",
        },
        {
          icon: "icon-md-directions-car",
          title: "公出",
          link: "/my/xc_official_leave",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.off-day-function-board {
  .off-day-function-board-main-content {
    @media (max-width: 960px) {
      flex-direction: column;
      min-width: 200px;
    }
    @media (max-width: 600px) {
      flex-direction: row;
    }
    .ws-btn {
      height: auto;
      flex: auto;
      .ws-btn__button {
        padding: 12px;
      }
    }
  }
}
</style>