export default {
  my_leave_day: {
    icon: 'icon-md-near-me',
    title: '我的 請假單',
    link: '/my/leave_day',
  },
  // my_subs_leave_day: {
  //   icon: 'icon-md-near-me',
  //   title: '我的 代理請假單',
  //   link: '/mysubs/leave_day',
  // },
  my_leave_day_remain_hours: {
    icon: 'icon-md-near-me',
    title: '我的 假別餘額',
    link: '/my/leave_day_remain_hours',
  },
  leave_day: {
    icon: 'icon-md-near-me',
    title: '請假管理',
    scopes: ['xc_leave_day-admin', 'xc_leave_day-subordinate-admin'],
    menu: [
      {
        title: '請假單',
        link: '/leave_day',
      },
      {
        title: '餘額查詢',
        link: '/leave_day_remain_hours',
      },
      {
        title: '休假數量變更',
        link: '/xc_leave_day_adjustment',
        scopes: ["xc_leave_day-admin"],
      },
      {
        title: '查詢請假單',
        link: '/xc_leave_day_search',
        scopes: ["xc_leave_day-admin"],
      },
    ]
  },
};