<template>
  <LayoutModelUpdate
    :id="$route.params.testing_case_id"
    v-bind="this.$store.state.stone_model.testing_case"
    :extendPostData="_extendPostData"
    :completeRedirectUrl="`/xc_project/${this.$route.params.id}/testing_case`"
    @complete="$_onComplete"
  ></LayoutModelUpdate>
</template>

<script>
export default {
  computed: {
    _extendPostData() {
      return {
        xc_project: this.$route.params.id,
      };
    },
  },
  methods: {
    $_onComplete() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
</style>