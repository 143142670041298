
const model = {
  options: {
    id: true,
    timestamp: true,
  },
  modelName: 'xc_password_pair',
  label: '密碼對',
  fields: {
    name: {
      label: '標題',
      type: 'text',
      required: true,
    },
    url: {
      label: '網址',
      type: 'link',
    },
    account: {
      label: '帳號',
      type: 'text',
      copyable: 1
    },
    password: {
      label: '密碼',
      type: 'password',
      copyable: 1
    },
    remark: {
      label: '備註',
      type: 'textarea'
    }
  },
  crudSetting: {
    index: {
      showFields: [
        'name',
        'url',
      ],
      inRowBtnRead: true,
    },
  }
}

export default model