import H_Router from '@/__stone/helpers/router'

const routes = [
  ...H_Router.getCrudTemplateRoutes({
    routes: [
      'index',
      // 'read',
      'update',
      'create',
    ],
    modelName: 'xc_milestone',
    middleware: 'auth',
    meta: {
      requiredScopes: ['xc_milestone-admin'],
    },
  }),
  ...H_Router.getResourceRoutes({
    routes: ['read'],
    modelName: 'xc_milestone',
    viewsPath: '__modules/xc_milestone/views',
    meta: {
      middleware: 'auth',
    },
  }),
]
export default routes