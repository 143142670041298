<template>
  <WsRow>
    <WsCol cols="4">
      <WsState
        v-model="state.city"
        :items="cityItems"
        :label="`${label}縣市`"
        type="select"
        @input="$_onCityInput()"
      />
    </WsCol>
    <WsCol cols="4">
      <WsState
        v-model="state.district"
        :items="districtItems"
        :disabled="districtDisabled"
        :label="`${label}行政區`"
        type="select"
        @input="$_onDistrictInput($event)"
      />
    </WsCol>
    <WsCol cols="4">
      <WsState
        v-model="state.zip"
        :label="`${label}郵遞區號`"
        type="text"
        @input="$_onZipInput()"
      />
    </WsCol>
  </WsRow>
</template>

<script>
export default {
  data() {
    return {
      state: {
        city: null,
        district: null,
        zip: null,
      },
      cityItems: [],
      districtItems: [],
      districtDisabled: true,
    }
  },
  methods: {
    async $_fetchCityItems() {
      try {
        const res = await this.$axios.get('/city')
        this.cityItems = res.data.data.map(item => ({
          text: item.name,
          value: item.id,
        }))
      } catch (error) {
        console.error(error)
      }
    },
    async $_fetchDistrictItems() {
      if (!this.state.city) {
        return
      }
      try {
        const res = await this.$axios.get('/district', {
          params: {
            city: this.state.city
          }
        })
        this.districtItems = res.data.data.map(item => ({
          text: item.name,
          value: item.id,
          zip: item.zip,
        }))
      } catch (error) {
        console.error(error)
      }
    },
    $_onCityInput() {
      this.$_fetchDistrictItems()
      this.state.district = null
      this.districtDisabled = false
      this.$emit('input', this.state)
    },
    $_onDistrictInput($event) {
      if (!this.state.zip) {
        const target = this.districtItems.find(district => district.value == $event)
        this.state.zip = target.zip || null
      }
      this.$emit('input', this.state)
    },
    $_onZipInput() {
      this.$emit('input', this.state)
    },
    async $_init() {
      try {
        await this.$_fetchCityItems()
        if (this.value) {
          this.state.city = this.value.city || null
          this.state.zip = this.value.zip || null
          if (this.value.city) {
            await this.$_fetchDistrictItems()
            this.districtDisabled = false
          }
          if (this.value.district) {
            this.state.district = this.value.district
            this.$_onDistrictInput(this.state.district)
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
  mounted() {
    this.$_init()
  },
  props: {
    value: Object,
    label: String,
  },
}
</script>